@import "~@progress/kendo-theme-material/dist/all.css";
/*@import "lookLikePixel2XL.css";*/

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important;
}

.MuiTabPanel-root {
  padding: 10px !important;

}

.full-page-loader{
  position: fixed;
  top: 7%;
  left: 3%;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
}
.full-page-loader-inside{
  position: relative;
  top: 30%;
  left: 45%;
}

.makeStyles-root-29 {
  padding: 1.5vh;
  padding-right: 0px !important;
}
/*.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 4px !important;
}*/

.PrivateNotchedOutline-legendLabelled-14 {
  padding-right: 14px !important;
}

.titlePiuLogo {
  padding-top: 4px !important;
  margin-left: -8px !important;
}

.now-my-place-icon-text-container {
  padding-top: 6px !important;
  margin-left: 4px !important;
}
.custom-end-adornment {
  width: fit-content;
  position: absolute;
  margin-left: 80%;
  margin-top: 1%;
}
#now-my-place-icon {
  /*background-image:*/
  /*        url('./../img/Icons/nowmyplace-icon.png');*/
  width:  20px;
  height: 46px !important;
  margin-top: 9px;
  margin-left: 25px;
  /*margin-right: 10px;*/
  /*margin-bottom: -10px;*/
}
#now-my-place-icon-text {
  /*width:  30px;*/
  height: 20px !important;
  /*margin-right: 10px;*/
  /*margin-bottom: -10px;*/
  margin-left: 10px;
  font-size: 10px;
}

/*-------------AUTOCOMPLETAMENTO BROWSER E CORRELATI-------------------*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid green;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.z-index-label-auth input:-webkit-autofill,
.z-index-label-auth input:-webkit-autofill:hover,
.z-index-label-auth input:-webkit-autofill:focus,
.z-index-label-auth textarea:-webkit-autofill,
.z-index-label-auth textarea:-webkit-autofill:hover,
.z-index-label-auth textarea:-webkit-autofill:focus,
.z-index-label-auth select:-webkit-autofill,
.z-index-label-auth select:-webkit-autofill:hover,
.z-index-label-auth select:-webkit-autofill:focus {

  z-index: 0;
}

.z-index-label-forms input:-webkit-autofill,
.z-index-label-forms input:-webkit-autofill:hover,
.z-index-label-forms input:-webkit-autofill:focus,
.z-index-label-forms textarea:-webkit-autofill,
.z-index-label-forms textarea:-webkit-autofill:hover,
.z-index-label-forms textarea:-webkit-autofill:focus,
.z-index-label-forms select:-webkit-autofill,
.z-index-label-forms select:-webkit-autofill:hover,
.z-index-label-forms select:-webkit-autofill:focus {
  z-index: -1;
}
/*--------------------------------------------------------------------------*/

/*UTILS*/
.display-true {
  display: inline !important;
}

.display-false {
  display: none !important;
}

/*-----------------------FONTS-----------------------*/
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Font/Gotham-Light.otf'); /* IE9 Compat Modes */
  src: url('../fonts/Gotham-Font/Gotham-Light.otf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Gotham-Font/Gotham-Light.otf') format('woff'), /* Modern Browsers */
  url('../fonts/Gotham-Font/GothamLight.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBold';
  src: url("../fonts/Gotham-Font/Gotham-Bold.otf");
}
@font-face {
  font-family: 'GothamBold';
  src: url("../fonts/Gotham-Font/Gotham-Bold.otf") format("woff");
}@font-face {
  font-family: 'GothamBold';
  src: url('../fonts/Gotham-Font/Gotham-Bold.otf'); /* IE9 Compat Modes */
  src: url('../fonts/Gotham-Font/Gotham-Bold.otf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Gotham-Font/Gotham-Bold.otf') format('woff'), /* Modern Browsers */
  url('../fonts/Gotham-Font/GothamBold.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Font/Gotham-Light.otf");
}
@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Font/Gotham-Light.otf") format("woff");
}
/*--------------------------------------------------------------------------*/



/*-----------FINESTRE DIALOG ------------ X in alto a destra*/
.dialog-staff-timetable-form-media-rule .k-button.k-window-action.k-dialog-action
.k-icon,
.dialog-expirience-form .k-button.k-window-action.k-dialog-action
.k-icon,
.delete-item-dialog .k-button.k-window-action.k-dialog-action
.k-icon,
.change-password-media-rule .k-button.k-window-action.k-dialog-action
.k-icon,
.appuntamento-dialog .k-button.k-window-action.k-dialog-action
.k-icon,
.change-profile-media-rule .k-button.k-window-action.k-dialog-action
.k-icon,
.dialog-staff-form-media-rule .k-button.k-window-action.k-dialog-action
.k-icon,
.equipment-dialog-form .k-button.k-window-action.k-dialog-action
.k-icon,
.location-form-media-rule .k-button.k-window-action.k-dialog-action
.k-icon,
.company-dialog-form .k-button.k-window-action.k-dialog-action
.k-icon,
.users-form-media-rule .k-button.k-window-action.k-dialog-action
.k-icon,
.add-planner-dialog .k-button.k-window-action.k-dialog-action
.k-icon,
.dialog-sub-change-pren .k-button.k-window-action.k-dialog-action
.k-icon
{
  font-size: 25px!important;
}
.delete-item-dialog {
  z-index: 10203 !important;
}
.appuntamento-dialog .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close
{
  margin-right: 24px !important;
}
.dialog-expirience-form .k-button.k-window-action.k-dialog-action
{
  margin-right: 24px !important;
}

.dialog-staff-timetable-form-media-rule .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close
.k-icon
{
  z-index: 1001 !important;
}
.dialog-expirience-form  .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close
.k-icon
{
  font-size: 28px!important;
  /*margin-left: -35px !important;*/
}
.dialog-sub-change-pren  .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close
.k-icon
{
  font-size: 28px!important;
  /*margin-left: -35px !important;*/
}
.dialog-staff-form-media-rule .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close
{
  margin-right: 12px !important;
}

.k-dialog-wrapper.company-dialog-form {
  z-index: 10000 !important;
}



/*--------------------------------------------------------------------------*/

/*.cond-met-label .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {*/
/*  font-size: 1rem!important;*/
/*}*/

.company_form {
  min-height: 780px !important;
  height: 780px !important;
  overflow-y: scroll !important;
}
.no-scroll-important {
  overflow-y: hidden !important; /* Hide vertical scrollbar */
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
}
.bottoni-tondi-margin {
  margin-top: 10px !important;
}
.bottoni-tondi span {
  font-size: 10px!important;
  height: 12px !IMPORTANT;
  width: 10px !important;

}
.generic-grid-metis .k-icon.k-i-filter {
  font-size: 21px !important;
}
.generic-grid-metis {

  font-family: 'Gotham'  !important;
}
.generic-grid-metis th {

  font-family: 'GothamBold'  !important;
}
.generic-grid-button{
  height: 36px !IMPORTANT;
  width: 36px !important;
}
.generic-grid-button span{
  margin-top: -7px !important;
  margin-left: -2px !important;
}
.bottoni-tondi-2 span {
  font-size: 24px!important;
  height: 12px !IMPORTANT;
  width: 10px !important;
  font-weight: lighter !important;
}
.buttons-inside-grids {
  font-size: 0.750rem !important;
}
.top-space-10p {
  margin-top: 18px !important;
}
.left-spacing-10p {
  margin-left: 18px !important;
}
.left-spacing-10pneg {
  margin-left: -9px !important;
}
.left-spacing-20p {
  margin-left: 36px !important;
}
.left-spacing-40p {
  margin-left: 80px !important;
}
.allinea-orari-chiusi {
  margin-left: 13px !important;
  max-width:  358px !important;
  flex-basis: 358px!important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.da-a-orari {

  max-width:  170px !important;
  flex-basis: 170px !important;
}
.icona-svg {
  fill: #3D8E3D !important;
}

/*-----------------------GRADIENTE + IMMAGINE DI SFONDO ------------*/
.custom-back{
  background-image:
          linear-gradient(to right, rgba(244,112,86,0.9) 5%, rgba(92,67,181,0.9) 95%),
          url('./../img/landscape.jpg');

  background-size: cover;
  background-repeat: no-repeat;
}
/*----------------------- ------------*/

/*DRAWER MENU*/
.MuiIconButton-edgeStart {
  margin-left: -18px !important;
}
/*----------------------- PER UNIFORMARE STILI E BOTTONI  ------------*/
/*Spesso sovrascrivo lo stile delle classi di material ui e/o kendo*/
.left-right-icon-calendar {
  padding: 8px 10px !important
}
.not-available-day {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  pointer-events: none !important;
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link{
  border-color: #5c43b5 !important;
  color: white;
  background-color:#5c43b5 !important;

}
.company-dialog-form .nmp-custom-tabstrip-tab{
  overflow-x: hidden !important;
}
.k-widget.k-upload.fileupload .k-dropzone
.k-button{
  font-size: 10px ;
}
.button-open-link  .k-icon.k-i-hyperlink-open{
  font-size: 21px !important;
}
.upload-label-custom {
  transform: translate(14px, -10px) scale(0.75) !important;
}
.k-input {
  font-family: 'Gotham' !important;
  font-weight: 700 !important;
}
.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
  font-family: 'Gotham'  !important;
  font-weight: 700 !important;
}
.k-chart-surface {
  font-family: 'Gotham'  !important;
}
.k-chart-surface text{
  padding-right: 1px !important;
  padding-left: 1px !important;
  margin: 2px !important;
  font-family: 'Gotham'  !important;

}
.k-chart-surface g{
  padding-right: 1px !important;
  padding-left: 1px !important;
  margin: 2px !important;
  font-family: 'Gotham'  !important;

}
.k-chart-surface path{
  margin: 2px !important;
  font-family: 'Gotham'  !important;

}
.k-chart-surface g text{
  padding-right: 1px !important;
  padding-left: 1px !important;
  margin: 2px !important;
  font-family: 'Gotham'  !important;

}
.big-error-message {
  font-size: 18px !important;
}
.errore-export-excel {
  padding-left: 15px;
  padding-bottom: 5px;
  height: 25px;
}
.errore-export-excel .k-form-error.k-text-start {
  font-weight: bolder !important;
  font-size: small;
}
.calendario-disabled  a.k-select {
  display: none !important;
}
.temp-appointment .k-event-actions{
  display: none !important;
}
.temp-appointment {
  background-color: 	hsla(10, 88%, 65%, 0.6) !important;
}
.highlight-row-from-plat {
  background-color: #f4705680 !important;

}
.labelina-fasce-prezzo {
  padding-top: 25px ;
  padding-right: 5px ;
  font-size: 1.2rem !important;
}
.container-input-fasce-prezzo {
  max-width: 24% !important;
  flex-basis: 24% !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.dialog-sub .dropdownModifica .k-dropdown {
  margin-top: -15px !important;
}
.fasce-anni-prezzi .k-widget.k-dropdown {
  width: 100% !important;
}
.dialog-sub .k-widget.k-window.k-dialog{
  width: 80vw!important;
}
.dialog-sub-change-pren .k-widget.k-window.k-dialog{
  width: 70vw!important;
  height: 75vh !important;
}

/*SCHEDULER VERSIONE MESE*/
/*.k-scheduler-layout.k-scheduler-layout-flex.k-scheduler-monthview {*/
/*  overflow: hidden !important;*/
/*}*/
body {
  overflow: hidden;
}
.slot-day-circle {
  margin-left: auto;
  margin-right: auto;
}
.icon-inside-day-slot {
  float: left;
  margin-top: 2px;
  margin-right: 5px;
}
.day-date-slot {
  font-size: 15px ;
  /*color: #5c43b5 !important;*/
  padding: 5px;
  width:  25px !important;
  height: 25px !important;
  border-radius: 100%;
  background-color: #f47056 !important;
}
.day-info-slot {
  font-size: 15px;
}
.work-load-white {
    background-color: white !important;
}
.work-load-light-green {
    background-color: #6efa73 !important;
}
.work-load-light-yellow {
    background-color: #f3f774 !important;
}
.work-load-light-red {
    background-color: #f77474 !important;
}

.work-load-closed {
    background-color: #a19f9f !important;
}

.scheduler-container-page
.kendo-scheduler .k-event .k-event-actions:first-child, .k-event .k-event-actions:first-child {
  margin: 2px 0.4ex 0 8px;
  margin-top: 15px !important;
  right: -15px !important;
  float: right;
  position: relative;
  opacity: 1;
  visibility: visible;
  line-height: normal;
}

.scheduler-container-page .k-scheduler-head .k-scheduler-cell.k-heading-cell {
  vertical-align: middle !important;
  padding-top: 3px !important;
}
.start-message-icon-title {
  margin-top: 0px !important;
  margin-left: 10px;
  cursor: pointer;
}
.riga-selezionata  {
  background-color: #f4705660 !important;
}
.riga-disabilitata  {
  background-color: #d9dadb ;
}
.contact-table .k-master-row :hover {
  cursor: pointer !important;
}
.contact-table .k-master-row {
  height: 25px !important;
}

.subscriber-message-form .k-widget.k-window.k-dialog{
  position: fixed;
  width: 30vw !important;
  height: 86vh;
  right: 0;
}
.k-message-list-content .k-timestamp{
  background-color: white;
  color: black;
  width: fit-content;
  padding: 5px 17px;
  border-radius: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.k-chat .k-message-box {
  margin-top: auto;
  margin-bottom: 0px!important;
}
.dialog-staff-timetable-form-media-rule .k-chat .k-message-list {
  min-height: calc( 75vh - 35px ) !important;
  max-height: calc( 75vh - 35px ) !important;

}
.subscriber-message-form .k-chat .k-message-list {
  min-height: calc( 70vh - 35px ) !important;
  max-height: calc( 70vh - 35px ) !important;
}
.k-chat .k-message-list {
  min-height: calc( 72vh - 35px ) !important;
  max-height: calc( 72vh - 35px ) !important;
}
.text-area-message-chat {
  height: 85px;
  overflow-y: auto;
}
.subscriber-message-form
.k-chat {
  max-width: none !important;
  height: 100% !important;
  font-family: 'Gotham'  !important;
  font-weight: 800;
  font-style: normal;
  background-image:  linear-gradient(to right, rgba(255,255,255,0.5) 5%, rgba(255,255,255,0.5) 95%), url('./../img/landscape.jpg');
  background-size: cover;
  background-position: center;

}
.k-chat {
  max-width: none !important;
  height: 91% !important;
  font-family: 'Gotham'  !important;
  font-weight: 800;
  font-style: normal;
  background-image:  linear-gradient(to right, rgba(255,255,255,0.5) 5%, rgba(255,255,255,0.5) 95%), url('./../img/landscape.jpg');
  background-size: cover;
  background-position: center;

}

.chart-container {
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.56);
  box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.56);
  border-radius: 25px;
  margin: 20px !important;
}
/*.chart-container .k-chart-surface {*/
/*  padding: 20px !important;*/
/*}*/
.MuiGrid-root.chart-container.MuiGrid-item.MuiGrid-grid-md-6 {
  flex-grow: 0;
  max-width:  59% !important;
  flex-basis: 59% !important;
}
.MuiGrid-root.chart-container.MuiGrid-item.MuiGrid-grid-md-4 {
  flex-grow: 0;
  max-width:  36.5% !important;
  flex-basis: 36.5% !important;
}
.alt-colonne {
  z-index: 1001 !important;

  /*position: absolute !important;*/
}
.field-attrezzatura-number-equipment-table {
  text-align: center !important;
}
.spinner-with-bg{
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100%!important;
}
.uniform-timetable {
  width: 60vw !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.align-side-by-side {
  width: 100px !important;
  margin-left: -25px;
}
.field-attrezzatura-number-equipment-table .MuiOutlinedInput-input {
  padding: 8px 14px;
}

.bottoni-calcola-prezzi {
  padding: 5px 25px !important;
}
.forza-bottoni-a-sinistra {
  text-align: left !important;
}
.custom-error-subs {
  justify-content: flex-end !important;
  height: 30px !important;
  margin-top: -10px !important;
}
.custom-error-subs div {
  justify-content: flex-end !important;
  margin-right: 2vw !important;
}
.fasce-anni-prezzi {
  margin-left: 7vw !important;
}
.fasce-anni-prezzi-2 {
  margin-left: 11vw !important;
}
.fasce-gruppi-prezzi-2 {
  margin-left: 14vw !important;
}

.fasce-anni-eta {
  max-width:  21% !important;
  flex-basis: 21% !important;
}
.fasce-anni-eta .MuiGrid-grid-sm-5.MuiGrid-grid-md-5.MuiGrid-grid-lg-5 {
  max-width:  50% !important;
  flex-basis: 50% !important;
}

.k-dialog-wrapper.dialog-expirience-form {
  z-index: 10000 !important;
}
.k-dialog-wrapper.alert-message-dialog {
  z-index: 13001 !important;
}
.price-to-discount {
  margin-left: 1vw !important;
}
.prezzinetti-section-title {
  max-width:  23% !important;
  flex-basis: 23% !important;
}
.prezzo-price-form {
  margin-top: 3vh !important;
  margin-left: 1.5vw !important;
  /* width: 44% !important; */
  max-width:  22% !important;
  flex-basis: 22% !important;
  font-size: 0.93rem !important
}
.tipo-prezzo-price-form {
  margin-top: 3vh !important;
  margin-left: 1vw !important;
  /* width: 44% !important; */
  max-width: 31% !important;
  flex-basis: 31% !important;
}
.content-padding-left input{
  padding-left: 18px !important;
}
.custom-tag span{
  display: inline-block;
}
.custom-tag .role-label {
  padding-left: 5px !important;
  transform: translate(0%, -15%);
}
.custom-tag .role-icon {
  transform-origin: -100% 50%;
  transform: translate(-23%, 20%);
}
.scheduler-container-page {
  padding-top: 0vh !important;
  padding-left: 0vw !important;
}
.scheduler-container-page .k-widget.k-scheduler.k-scheduler-flex.k-floatwrap {
  overflow-y: hidden !important;
  min-height: calc( 93vh - 25px ) !important;
  max-height: calc( 93vh - 25px ) !important;
}
.k-scheduler {
  line-height: 1.7 !important;
}
.k-scheduler-layout-flex .k-scheduler-cell {
  min-height: 1.7em !important;
}
.field-attrezzatura-equipment-table .k-widget.k-dropdown{
  padding-left: 0px !important;
}
.align-to-rightside {
  margin-right: 0vw !important;
  margin-left: 83.5vw !important;
  margin-top: 1vh !important;
}
.align-to-center {
  margin-right: 0vw !important;
  margin-left: 42vw !important;
  margin-top: 2vh !important;
}
.add-period-season {
  margin-top: 10px !important;
}

.alert-message-dialog .k-window-title.k-dialog-title{
  color: #f47056 !important;
}
.alert-message-dialog .message-zone svg{
  display: inline !important;
  /*margin-top: 10px !important;*/
}
.alert-message-dialog .message-zone p{


}


.k-multiselect .k-chip-content {
  padding-left: 4px !important;
  font-size: 16px !important;
  padding: 4px;
}
.availability-grid-container
.k-multiselect .k-chip-content {
  padding-left: 4px !important;
  font-size: 16px !important;
  padding: 7px;
}

.title-bar-grid div{
  display: inline !important;
}
.title-bar-grid button{
  display: inline !important;
}
.grid-button .MuiSvgIcon-root {
  font-size: 1.8rem !important;
}
.grid-button  {
  margin-top: 2px !important;
  /*margin-left: -15px !important;*/
}
.multiselect-label-nuova.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  margin-top: -5px !important;
}

.css-2b097c-container {

  padding: 0px 5px !important;
}
.dropdownModifica-expirience .k-textbox-container::after {
  background-color: white !important;
}
.dialog-sub .dropdownModifica .k-textbox-container::after {
  background-color: white !important;
}
.custom-unif-dropdown-focus:focus-within  .labelModifica {
  color: #5c43b5 !important;
}
.custom-unif-dropdown-focus:focus-within  {

  border-color: #5c43b5 !important;
  -webkit-box-shadow: inset 0px 0px 0px 1px #5c43b5;
  box-shadow: inset 0px 0px 0px 1px #5c43b5;
}
.custom-unif-dropdown-focus div span div ul li {
  font-size: 15px !important;
}
.k-picker-solid {
  background-color: inherit !important;
  border: 0px !important;
}
.k-input-solid {
  background-color: inherit !important;
  border: 0px !important;
}

.custom-unif-dropdown-focus .k-picker-solid {
  background-color: inherit;
  border: 0px !important;
}

.k-animation-container.k-animation-container-relative.k-list-container.k-reset.k-animation-container-shown .roles-bolder {
  font-family: 'GothamBold'  !important;
  font-weight: 700;
  font-style: normal;

}

/*.k-animation-container.k-animation-container-relative.k-list-container.k-reset.k-animation-container-shown .button {*/
/*  background-color: #5c43b5 !important;*/

/*}*/
.k-animation-container.k-animation-container-relative.k-group.k-reset .k-time-accept.k-button.k-primary{
  background-color: #5c43b5 !important;
}


.notifiche-switch-visible {
  padding-top: 25px !important;
}
.notifiche-switch {
  padding-left: -6px !important;
}
.attiva-switch {
  padding-top: 15px !important;
}
.bottom-spacing {
  padding-top: 15px !important;
}
.top-spacing {
  margin-top: 15px !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #5c43b5 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:  #5c43b5 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #5c43b5 !important;
  font-weight: bolder;
  border-color: rgba(92,67,181,0.8) !important;
  background-color: rgba(92,67,181,0.2) !important;
}
.WithStyles\(ForwardRef\(Tab\)\)-selected-35 {
  border-color: rgba(92,67,181,0.8) !important;
  background-color: rgba(92,67,181,0.2) !important;
}
.custom-unif-icon-title {
  font-size: 30px !important;
  padding-top: 3px !important;
  /*height: 10px !important;*/
}
.allinea-colonna-centro {
  text-align: center !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
.allinea-colonna-centro .k-column-title{
  text-align: center !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
.allinea-colonna-centro-header .k-cell-inner {
  justify-content: center !important;
}
.title-right-icon-container {
  margin-top: -16px !important;
}
.custom-unif-icon-title {
  color: #5c43b5 !important;

}
.title-right-icon {
  margin-top: -6px !important;
}
.custom-filter-button {
  margin-right: 40px !important;
  border-radius: 50px;
  border: none;
  padding: 8px;
}
.MuiFormLabel-colorSecondary.Mui-focused {
  color: #5c43b5 !important;
}
.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline  {
  border-color: #5c43b5 !important;
}
.k-switch-handle {
  width: 24px !important;
  height: 24px  !important;
}
.MuiSwitch-thumb {
  margin-top: -1px !important;
  width: 24px !important;
  height: 24px  !important;
}
.MuiSwitch-colorSecondary.Mui-checked{
  color: #5c43b5 !important;
}

.equipment-table-exp .MuiSwitch-colorPrimary.Mui-checked {
  color: #5c43b5 !important;
}
.k-switch-on .k-switch-container ,  .k-switch-on .k-switch-track {
  background-color: rgba(92,67,181,0.6) !important;
}
.k-switch-on .k-switch-handle, .k-switch-on .k-switch-thumb{
  background-color: #5c43b5 !important;
}
.equipment-table-exp .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #5c43b5 !important;
}

.MuiSwitch-track {
  width: 46px !important;
  height: 16px  !important;
}
.k-switch-container {
  width: 46px !important;
  height: 16px  !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #5c43b5 !important;
}
.custom-unif-font .MuiInputBase-input {
  font-family: 'Gotham'  !important;
  font-weight: 700;
  font-style: normal;
}
.custom-unif-font .k-dropdown-wrap .k-input {
  font-family: 'Gotham'  !important;
  font-weight: 700;
  font-style: normal;
}


.k-animation-container.k-animation-container-relative.k-list-container.k-reset.k-animation-container-shown {
  font-family: 'Gotham'  !important;
  font-weight: 700;
  font-style: normal;
}
.custom-unif-font li {
  font-family: 'Gotham'  !important;
  font-weight: 700;
  font-style: normal;
}
.custom-unif-font-2{
  font-family: 'Gotham'  !important;
  font-weight: 800;
  font-style: normal;
}
.custom-unif-font-2 textarea {
  font-family: 'Gotham'  !important;
  font-weight: 800;
  font-style: normal;
}
.custom-unif-font .MuiInputBase-root {
  line-height: 1.2876em;
}
.custom-unif-font-bold {
  font-family: 'GothamBold'  !important;
  font-weight: bolder;
  font-style: normal;
}
.MuiTab-root {
  font-family: 'GothamBold'  !important;
}
.custom-unif-font {
  font-family: 'Gotham'  !important;
  font-weight: 500;
  font-style: normal;
}
.MuiIcon-colorPrimary {

  color: #5c43b5 !important;
}
.k-list .k-item.k-state-selected {
  color:  #5c43b5;
}
.k-list .k-item.k-state-selected:hover {
  color:  #5c43b5;
}
.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
  color: #5c43b5 !important;
}
.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {
  color: #5c43b5 !important;
}
a:link {
  color: #5c43b5 !important;
}
.custom-unif-viola {

  color: #5c43b5 !important;
}
.string-at-right-of-icon {
  font-size: 16px;
  padding: 5px;
  margin-left: 5px;
}
.select-slot-btn {
  margin-top: -4px !important;
  margin-left: 5px !important;
}
.MuiDrawer-paper {
  z-index: 40 !important;
}
.custom-unif-viola-back {
  color: white !important;
  font-weight: bolder !important;
  background-color: #5c43b5 !important;
}
.custom-unif-arancione {

  color: #f47056 !important;
}
.bottoni-bordo-arancione {
  border-color: #f47056 !important;
}
.bottoni-bordo-viola {
  border-color: #5c43b5 !important;
}
.custom-unif-grigio-back {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.not-clicked {
  color: black !important;
}

.custom-unif-unselected-back {
  border-color: #9e9e9e;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px 20px 0 0;
  color: rgba(0, 0, 0, 0.54);
}
.listview-link-container .k-listview-content{
  padding-left: 25px
}
.listview-link {
  padding: 7px 0px;
  display: list-item;
}

.link-label-dialog {

  font-weight: bold !important;
  font-family: 'Gotham' !important;
}

.custom-unif-selected-back {
  color: #5c43b5 !important;
  font-weight: bolder;
  border-color: rgba(92,67,181,0.8) !important;
  background-color: rgba(92,67,181,0.2) !important;
}
.guest-form {
  z-index: 50 !important;
}
.guest-form .k-input-inner {
  padding: 8px 8px !important;
}
.guest-form  .flag-container{
  padding-top: 25px !important;
}
.guest-form
.k-widget.k-window.k-dialog
{
  height: auto ;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
}
.guest-form.guest-details-dialog
.MuiButton-root
{
  font-size: 0.700rem;
}
.guest-form.guest-details-dialog
.k-widget.k-window.k-dialog
{
  margin-top: 5vh;
  height: auto ;
  max-height:95vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
}
.align-center-inside-dialog {
  margin-left:  auto !important;
  margin-right: auto !important;
}
.guest-form.guest-details-dialog
.k-widget.k-grid
{
  min-height: 70vh !important;
  max-height: 70vh !important;
}
.guest-form.guest-details-dialog
.k-grid-header ,
.guest-form.guest-details-dialog
.k-grid-footer
{
  padding-inline-end: 0 !important ;
}
.guest-form.guest-details-dialog
.k-grid-content
{
  overflow-y: overlay !important;
}

.guest-details-dialog .k-window-content.k-dialog-content{
  overflow: hidden !important;
}
/*.guest-form.edit*/
/*.k-widget.k-window.k-dialog*/
/*{*/
/*  height: 65vh ;*/
/*}*/

.two-rows-buttons {
  margin-top: 1vh !important;
}
.experiences-grid-reseller .k-input-inner,
.receptionist-form .k-input-inner
{
  padding: 0px !important;
}

.custom-unif-arancione-back {
  color: white !important;
  font-weight: bolder !important;
  background-color: #f47056 !important;
}
.custom-unif-border-radius {
  border-radius : 5px !important;
}
.custom-unif-button {
  color: white !important;
  font-size: 55px !important;
  font-weight: bolder !important;
  /*background-color: #5c43b5 !important;*/
}
.custom-button-annulla {
  padding-right:  16px !important;
  padding-left:   16px !important;
}
.spacer-usercontrol {
  height: 10px !important;
}
.rows-piccole {
  height: 35px !important;
}
.title-row {
  height: 80px !important;
}
.rows-grandi {
  height: 90px !important;
}
.rows-grandi-2 {
  height: 110px !important;
}
.rows-grandi-staff {
  height: 100px !important;
}
.rows-grandi-3 {
  height: 140px !important;
}
.rows-grandi-5 {
  height: 170px !important;
}
.custom-form-spacing {
  margin-bottom: 1vh !important;
}
.ultima {
  margin-bottom: 2vh !important;
}
.ultima-staff {
  margin-bottom: 1.5vh !important;
}
label {
  font-family: GothamBold  !important;
  background-color: white;
  padding-right: 5px !important;

}
input {
  font-family: Gotham  !important;
  font-weight: bolder !important;
}
.k-lg-date-format {
  font-family: Gotham  !important;
  font-weight: bolder !important;
}
.MuiButton-label {
  font-family: GothamBold  !important;
}
.MuiTypography-body1 {
  font-family: Gotham  !important;
  font-weight: bold !important;
}
.MuiTypography-h3 {
  font-family: Gotham  !important;
}
.MuiTypography-h4 {
  font-family: Gotham  !important;
}
.MuiTypography-h5 {
  font-family: Gotham  !important;
}
.MuiTypography-h6{
  font-family: Gotham  !important;
}

.custom-green-color {
  color: #3D8E3D;
}
.custom-red-color {
  color: #B24C4C;
}
.custom-blue-color {
  color: #3D668E;
}
.custom-button-uniformed {
  border-radius: 25px !important;
}

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
/*BOTTONI IN GENERALE */
.k-button {
  border-radius: 25px;
}
.k-chip {
  border-radius: 25px;
}

/*----------------------- ------------*/
/*USER CONTROL*/
.item-user-control {
  padding:  0px 20px !important;
  margin:   2px 20px !important;
}
.title-user-control {
  padding-top: 10px !important;
}
.title-container-user-control {
  margin-top: 30px !important;
}
/*ROLE BOX*/
.button-media-rule {
  font-size: 0.7rem !important;
}
.custom-spacing-rolebox{
  width: 60px !important;
}
.logout-media-rule {
  padding-top:    14px !important;
  padding-bottom: 14px !important;
  margin-right: 0px !important;
}
.buttons-user-control {
  border: 0px !important;
  padding: 0px !important;
}
.buttons-user-control:hover {
  border: 0px !important;
  background-color: white !important;
}
.user-control-container {
  width: 340px !important;
}


/*FINE ROLE BOX*/
/*//upload custom*/
.k-upload {
  border-radius: 5px !important;
}
.k-upload-action:hover {
  box-shadow: 0px 0px 14px 0px #000000 !important;
  font-weight: bolder !important;
}
.not-visible{
  display: none !important;
}
.inline-contents {

  float: left;
  width: 100% !important;
}
.file-upload-icon {

  float: left !important;
}
.k-upload .k-upload-button{
  margin-left: 10px;
  color: white !important;
  background-color: #5c43b5 !important;
  font-family: 'GothamBold'  !important;
}
.k-upload .k-dropzone .k-dropzone-hint {
  padding-right: 20px !important;
  font-weight: bold;
  font-size: 16px !important;
}
.file-name-upload {
  width: 70vw !important;
  float: left !important;
  padding: 0vh 2vw;
  padding-left: 0.5vw !important;
  font-size: 1.2em !important;
}
.reseller-form .file-name-upload {
  width: 70% !important;
}
.delete-button-upload {
  display: inline !important;
  float: right !important;
  /*margin-right: 2vw !important;*/

}
.k-progressbar-cust {
  transition: opacity 1s ease-in-out 0s;
  opacity: 1;
}
.k-progressbar {
  color: white !important;
  background-color: white !important;
}
.full-width-important {
  width: 100% !important;
}
.status-error {
  display: none;
  color:red;
}
.status-success {
  display: none;
  color: #37b400;
  padding-bottom: 10px!important;
  padding-top: 5px!important;
  margin-bottom: 10px !important;
  border-width: 0px !important;
}
.k-upload .k-file-success .k-progress {
  background-color: white !important;
}
.status-visible {
  display: inherit !important;
  margin-top: -16px !important;
  padding-left: 1.8vw;
  padding-bottom: 20px !important;
  height: 10px !important;
  font-size: 1.1em;
}
.error-color {
  background-color: red !important;
}
.big-height-upload {
  height: auto !important;
}
/*---------------------------*/

/*-------------------------- AUTOCOMPLETE COMUNE --------------*/
.css-2b097c-container {
  z-index: 998 !important;
}
.css-2613qy-menu {
  opacity: 1;
  background-color: white !important;
  position: absolute !important;
  z-index: 999 !important;
}
.css-yk16xz-control {
  border: none !important;
  border-style: none !important;
  border-width: 0px !important;
}
.css-1pahdxg-control{
  box-shadow: none !important;
  border: none !important;
  border-width: 0px !important;
}
.css-1pahdxg-control:hover {
  border: none !important;
  border-width: 0px !important;
}
.dropdownCitta {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 8px 0vw !important;
  height: 56px!important;
  font-weight: 800 !important;

}
.css-1wa3eu0-placeholder {
  font-size: 1.00rem !important;
  /* font-weight: 450 !important; */
  color: black !important;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

/*---------------------------*/

.dropdown-responsive-mediarule {
  height: 50px!important;
}

/* ogni cella del planner deve avere dimensione fissa */
.message-subscriber {
  z-index: 999 !important;
}
.select-week-title {
  width: 58vw !important;
  position:fixed;
  /*z-index: 999 !important;*/
  text-align: center !important;
  top: 1vh !important;
}
.dialog-staff-timetable-form-media-rule
.timetable-row-container{
  margin-left: -1vw !important;
}
.staff-change-single-day-time
.timetable-row-container{
  margin-left: 4vw !important;
}
.bottoni-timetable-container {
  display: inline !important;
}
.bottoni-timetable-container button {
  margin-top:     10px !important;
  margin-bottom:  10px !important;
}
.align-center-inside-container {

  margin-left: auto !important;
  margin-right: auto!important;
}
.align-content-inside {
  padding-left:   2% !important;
  padding-right:  2%!important;
}
.timetable-container {
  margin-top: 0vh !important;
  text-align: center;
}
.select-week-title-child {
  margin-left:  1vw !important;
  margin-right: 1vw !important;
}
.frecce-planner-timetable {
  font-size: 1.2rem !important;
  margin-top: 10px !important;
}
.frecce-settimana {
  margin-top:10px !important;
  /*text-align: center !important;*/
}
.frecce-settimana-sx {
  text-align: right;
}

.frecce-settimana-dx {
  text-align: left;
}

#form-company  .MuiInputLabel-outlined {
  /*z-index: 0 !important;*/
}
#form-company .MuiGrid-spacing-xs-3 {
  width: 100% !important;
}
.button-planner-toolbar {
  border-width: 2px !important;
  height: 34px !important;
  margin-right: 10px !important;
  font-size: 14px !important;
}
.iniziali-button {
  height: 34px !important;
  width: 34px !important;
  font-size: 14px !important;
  text-transform: uppercase;
}
.contact-table-expinfo {

  float: left;
  margin-top: 5px !important;

}

.contact-table-nameinfo{
  float: left;
  margin-left: 15px !important;
  padding-top: 5px !important;
  margin-top: 5px !important;
}
.custom-row-item div {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  /*width: 90%;*/
}
.custom-row-item.contact-row{
  width: 40% !important;
}
.custom-row-item.experience-row{
  width: 80% !important;
}
.messages-number {
  background-color: #f47056;
  /* width: 20px; */
  /* height: 20px; */
  padding: 0px 6px;
  border-radius: 50px;
  font-size: 0.6rem;
  color: white;
  font-weight: bolder;
  position: relative;
  z-index: 999;
  /* margin-right: -100px; */
  /* margin-left: auto; */
  float: right;
  left: -270px;
  top: 5px;
}
.badge-numnotifiche {
  background-color: #f47056;
  padding: 6px 10px;
  border-radius: 50px;
  font-size: 0.5rem;
  color: white;
  font-weight: bolder;
  z-index: 999;
  float: left;
  margin-left: -10px;
}

.button-planner-toolbar .MuiButton-label{
  font-size: 15px !important;
}
/*CUSTOMIZZAZIONE ORARIO SCHEDULER*/
.scheduler-view-day .k-scheduler-head .k-scheduler-cell.k-heading-cell:hover{
  /*color: #f47056 !important;*/
  text-decoration: underline;
  -webkit-text-decoration-color: black; /* safari still uses vendor prefix */
  text-decoration-color: black;
}
.k-major-cell  {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.k-major-cell ~ div {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.utilizzo-chip {
  border-width: 0px !important;
}
/*GRIGLIA UTENTI - SUPER ADMIN*/
.grid-orari.k-widget.k-grid {
  min-height: calc( 93vh - 100px) !important;
  max-height: calc( 93vh - 100px) !important;
}
.partecipants-table .k-widget.k-grid {
  min-height: calc( 91vh - 64px) !important;
  max-height: calc( 91vh - 64px) !important;
}
.grid-orari-agenda.k-widget.k-grid  {
  min-height: calc( 87vh - 100px) !important;
  max-height: calc( 87vh - 100px) !important;
}
.users_grid [data-title]:hover::after {
  margin-left: -100px !important;
  /*margin-top:-50px !important;*/
  /*position: absolute !important;*/
  /*z-index: 999 !important;*/
}

/*.users_grid th[aria-colindex="6"] {*/
/*  display: none !important;*/
/*}*/
.k-widget.k-grid {
  min-height: calc( 94vh - 64px) !important;
  max-height: calc( 94vh - 64px) !important;
}
.k-widget.k-grid .k-widget.k-dropdown{
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding-left: 1vw;
}

.k-filtercell-operator .k-widget.k-dropdown.k-dropdown-operator {
  border: none!important;
  border-width: 0px !important;
  border-style: none !important;
  border-radius: 0px !important;
  padding-left: 0vw !important;
}
.k-widget.k-dropdown.k-dropdown-operator  .k-dropdown-wrap{
  border: none!important;
  border-width: 0px !important;

}

.k-widget.k-dropdown.k-dropdown-operator  .k-dropdown-wrap .k-select{
  border: none!important;
  border-width: 0px !important;

}


/* popup di select di scheduler kendo */
.k-scheduler-edit-dialog {
  z-index: 1201 !important;
}

.MuiFormLabel-root {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}
.MuiInputBase-root {
  font-size: 1.05rem !important;
  font-weight: 450 !important;
}
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  border-radius: 25px;
}

/* popup di select di kendo */
.k-animation-container {
  z-index: 15003 !important;
}
/* popup dei select di material ui */
.MuiPopover-root {
  z-index: 10001 !important;
}
/* valore dialog kendo -> z-index:10001 */
.users_grid.guests-grid > div > div.k-grid-header > div > table > thead > tr > th:nth-child(5) > span > span > span {

  margin-right: auto !important;
  margin-left: auto !important;
}
.users_grid.guests-grid .k-filtercell-operator {
  display: none !important;
}



.k-scheduler-head
.k-scheduler-group:nth-child(1)
.k-scheduler-row:nth-child(2) {
  display: none;
}
.k-scheduler-head .k-scheduler-group:nth-child(2) {
  display: none;
}
.prezzo-input-exp .MuiFormControl-root{
  width:100% !important;
}


@media only screen and (max-width: 411px) {
  .prezzo-input-exp .MuiFormControl-root {
    width: 90% !important;
  }
}
.scheduler-view-day .k-scheduler-cell {
  /* background-color: #f80435 !important; */
  /* min-width: 280px; */
  min-width: calc((100vw - 5vh - 60px - 50px) / 6);
  overflow: hidden !important;
  padding: 0 !important;
}

/* ogni cella header del planner ha il bordo 1px da considerare */
/* forse non serve più
.k-heading-cell {
  min-width: 199px !important;
} */

/* colonna orari planner */
.k-side-cell,
.k-major-cell {
  justify-content: center !important;
  background-color: white;
  min-width: 50px !important;
  position: sticky !important;
  position: -webkit-sticky;
  overflow: visible !important;
  left: 0 !important;
  z-index: 3;
}
/* current time */
.k-current-time,
.k-current-time-arrow-right {
  /* position: sticky !important; */
  left: 0px !important;
  width: 100%;
  height: 1px !important;
  z-index: 3;
}

/* header dove ci sono le risorse */
.k-scheduler-cell,
.k-heading-cell {
  background-color: white;
  /* height: 30px; */
}
.k-scheduler-head {
  height: 30px;
  z-index: 4 !important;
}
.k-scheduler-layout {
  flex: none !important;
}

/* container scheduler */
.k-scheduler-layout,
.k-scheduler-layout-flex,
.k-scheduler-day-view {
  display: grid;
  overflow: scroll;
  /* overflow-y: scroll; */

  max-height: calc(100vh - 5vh - 100px) !important;

  /*max-height: calc(100vh - 8vh - 100px) !important;*/
  height: auto;
}

/*SELETTORE BARRA BLU SOTTO*/
.MuiTabs-indicator
{
  width: 0px !important;
  display: none !important;
}
/*---------*/


/* data picker scheduler */
.k-widget {
  width: auto;
}
.etichetta-eta >
.k-widget {
  width: 100% !important;
}
.k-text-disabled {
  opacity: 1;
}

.k-scheduler > div > .k-button {
  border-radius: 20px;
}
.k-scheduler > div > .k-datepicker > .k-button {
  border-radius: 20px;
}

/* TABSTRIP COMPONENT */
.k-tabstrip-items{
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  letter-spacing: 0.01071em;
  font-size: 14px;
  line-height: 1.5;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: inherit;
  box-sizing: inherit;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}
.k-tabstrip-items li {
  text-align: center !important;
  -webkit-font-smoothing: antialiased;
  font-family: 'GothamBold'  !important;
  margin: 0;
  outline: 0;
  align-items: center;
  user-select: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.8rem;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  line-height: 1.5 !important;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 160px;
  flex-grow: 1;
  max-width: none;
  flex-basis: 0;
  flex-shrink: 1;
  color: rgba(0, 0, 0, 0.38);
  border-color: #9e9e9e !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 20px 20px 0 0 !important;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
   margin-bottom: 0px !important;
}
.k-tabstrip-items .k-item.k-active {
  color: #5c43b5 !important;
  font-weight: bolder;
  border-color: rgba(92,67,181,0.8) !important;
  background-color: rgba(92,67,181,0.2) !important;
}
.k-tabstrip-items .k-item {
   display: block !important;
 }
.k-tabstrip-items-wrapper .k-item.k-active::after {
  border: none !important;
}
.k-tabstrip-items li span:after {
  text-align: center !important;
}
/* TABSTRIP COMPONENT */

/* Reseller tab2*/
.k-widget.k-grid.generic-grid-metis.experiences-grid-reseller {
  min-height: 80vh !important;
  max-height: 80vh !important;
}

/*LABEL */
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  font-size: 1.2rem !important;
  background-color: white !important;
  padding-right: 20px!important;
}

/*Prevalentemente per risolvere il problema di "riga del contorno che entra nel testo della label"*/
.label-no-white-background
.labelModifica.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {

  background-color: rgba(255,255,255,0.6) !important;
  padding-right: 0px !important;
}

.change-password-media-rule .MuiInputLabel-outlined {

  padding-right: 20px!important;
  background-color: white !important;
}
.company-form .MuiInputLabel-outlined {

  padding-right: 20px!important;
  background-color: white !important;
}
.reseller-form .MuiInputLabel-outlined {

  padding-right: 20px!important;
  background-color: white !important;
}
.reseller-form {
  margin-top: 1vh;
}

.dialog-expirience-form .MuiInputLabel-outlined {

  padding-right: 20px!important;
  background-color: white !important;
}

.add-planner-dialog .MuiInputLabel-outlined {

  padding-right: 20px!important;
  background-color: white !important;
}
.appuntamento-dialog .MuiInputLabel-outlined {

  padding-right: 20px!important;
  padding-left:4px!important;
  background-color: white !important;
}
.appuntamento-dialog .MuiInputLabel-outlined {

  background-color: white !important;
}
.appuntamento-dialog .MuiTabPanel-root {
  padding: 0px !important;
}
.staff-form-media-rule .MuiInputLabel-outlined {

  background-color: white !important;
}
.equipment-dialog-form .MuiInputLabel-outlined {

  background-color: white !important;

  padding-right: 20px!important;
}
.users-form-media-rule .MuiInputLabel-outlined {

  background-color: white !important;
}
.location-form-media-rule .MuiInputLabel-outlined {

  background-color: white !important;
}
.change-password-dialog .MuiInputLabel-outlined {

  background-color: white !important;
  padding-right: 20px!important;
}
.forgot-password-dialog .MuiInputLabel-outlined {

  background-color: white !important;
  padding-right: 20px!important;
}
.k-label {
  font-size: 1.2rem !important;
}


/* DropDown "orario al pubblico personalizzato" */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  max-height: 300px !important;
  border-radius: 25px;
}

/* container dropdown ^ "settimana"*/
.MuiFormControl-root.makeStyles-formControl-55.MuiFormControl-fullWidth {
  padding: 6px 0px !important;
}

/* container dropdown ^ "settimana" orario pubblico personalizzato*/
.MuiFormControl-root.makeStyles-formControl-344.MuiFormControl-fullWidth {
  padding: 6px 0px !important;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 25px;
}

.MuiCardActions-spacing > :not(:first-child) {
  margin-left: 50px !important;
}

/*AGGIUNGE I DUE BORDINI TONDI A DESTRA E A SINISTRA PER LE SOTTOSEZIONI NEI FORM*/
.spacing-left-timetable {
  margin-right: 5% !important;
}

.fixed-width {

}
.label-timetable {
  padding-left: 10px !important;
}
.title-giorno-timetable {
  padding-top: 10px !important;
}
.title-giorno-timetable-closed{
  /*padding-top: 16px !important;*/
}
/*.sab-dom-timetable {*/
/*  padding-top: 6px !important;*/
/*}*/
.spacing-top-timetable{
  margin-top: 20px !important;
}
.allinea-orari-chiusi .MuiInputBase-input.MuiOutlinedInput-input {
  padding-left: 25px !important;
}
.allinea-orari-chiusi .MuiOutlinedInput-input {
  padding: 10px 14px !important;
}
.day-name-timetable {
  margin-right: -25px !important;
  text-align: right !important;
  /*margin-left: -6vw !important;*/
}
.a-bit-of-margin-top {
}
.custom-section-forms-timetable {
  margin-top: -10px !important;
  /*margin-left: 4vw !important;*/
  border: 1px solid grey;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-radius: 5px;
  border-left: 0px;
  padding-left: 16px !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;

}
.custom-section-forms-exp{
  margin-top: 5px !important;
  margin-left: 5px !important;
  width: 100% !important;
  border: 1px solid grey;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-radius: 5px;
  padding-bottom: 0px !important;
  padding-top: 6px !important;
  padding-left: 10px !important;
  padding-right: 6px !important;
}
.custom-section-forms {
  margin-top: 5px !important;
  margin-left: 5px !important;
  padding-top: 6px !important;
  width: 100% !important;
  border: 1px solid grey;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px !important;
  border-radius: 5px;
  padding-left: 10px !important;
  padding-right: 6px !important;
}
/* serve per non far comparire i bottoni nel dialog degli appuntamenti */
/* .k-dialog-buttongroup {
  display: none;
} */

.MuiFormHelperText-root.MuiFormHelperText-contained {
  font-size: 1rem;
}

.dropdownNazionalita {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  padding: 0px 0vw !important;
  padding-bottom: 5px !important;
  padding-top: -8px !important;
}
.dropdownNazionalita .k-textbox-container {
  width: 98% !important;
}
.condiz-meteo .dropdownModifica {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 4px 0vw !important;
}
.dropdownModifica {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 10px 0vw !important;
  height: 57px !important;
}
.receptionist-form .dropdownModifica{
  padding: 14px 0 !important;
}
.dropdownModifica-expirience {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 11px 0vw !important;
}
.dropdownModifica2{
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 0px 0vw !important;
}
.dropdownModifica2 .k-textbox-container{
  padding-top: 14px !important;
}

.inputTimePicker-custom {
  margin: 0vh 0.5vw !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 0.3vh 0.5vw !important;
  margin: 10px !important;

}

.inputTimePicker-custom-appointmentform {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px;
  padding: 1vh 0vw !important;
  width: 100% !important;
}

.k-timepicker .k-picker-wrap {
  border: none !important;
  border-style: none !important;
  border-width: 0px !important;
}
.k-picker-wrap.k-picker-wrap {
  border: none !important;
  border-style: none !important;
  border-width: 0px !important;
}
.k-numerictextbox .k-numeric-wrap {
  border: none !important;
  border-style: none !important;
  border-width: 0px !important;
}
.spanFiglia-daIngrandire > span {
  width:98% !important;
}
.labelModifica{
  background-color: white !important;
}

.k-multiselect.k-floatwrap {
  border: none !important;
}
.k-select-wrap.k-floatwrap {
  border: none !important;
}
.k-dropdown-wrap{
  border: none !important;
}
.dropdownNazionalita > div> span > span.k-label {
  display: none !important;
}
.dropdownModifica > div> span > span.k-label {
  display: none !important;
}
.dropdownModifica2 > div> span > span.k-label {
  display: none !important;
}
.dropdownModifica3 > div> span > span.k-label {
  display: none !important;
}
.spacer {
  margin-bottom: 1vh !important;
}
.da-non-visualizzare  {
  display: none !important;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  z-index: 99999;
}
[data-title]:after {
  content: attr(data-title);
  position: absolute;
  /*bottom: -1.6em;*/
  left: 100%;
  padding: 8px 8px 8px 8px;
  color: #666;
  width: fit-content;
  /*word-spacing:9999px;*/
  white-space: pre-line;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #666;
  -webkit-box-shadow: 0px 0px 4px #666;
  box-shadow: 0px 0px 4px #666;
  background-color: white;
  /*background-image: -moz-linear-gradient(top, #f0eded, #bfbdbd);*/
  /*background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f0eded), color-stop(1, #bfbdbd));*/
  /*background-image: -webkit-linear-gradient(top, #f0eded, #bfbdbd);*/
  /*background-image: -moz-linear-gradient(top, #f0eded, #bfbdbd);*/
  /*background-image: -ms-linear-gradient(top, #f0eded, #bfbdbd);*/
  /*background-image: -o-linear-gradient(top, #f0eded, #bfbdbd);*/
  opacity: 1;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
.periodi-apertura-title {
  margin-left: 18px !important;
}
/*LOGIN*/
.login-form-classes .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  display: none !important;
}

/*Tabella aziende in superadmin*/
/*.companies_grid tr{*/
/*  height: 10px !important;*/
/*}*/

/*TABELLE IN GENERALE*/
.k-widget.k-grid.agende-table {
  height:     auto !important;
  min-height: auto !important;
  max-height: 300px !important;
  overflow-y: scroll;
}
.agende-table .k-grid-header {
  display: none!important;
}
.agende-table {
  height:     200px !important;
  min-height: 200px !important;
  max-height: 200px !important;
}
.agende-table .k-grid-container{
  height: 200px !important;
}
.agende-table .k-grid-container .k-grid-content.k-virtual-content{
  height: 200px !important;
}
.agende-table .k-toolbar.k-grid-toolbar {
  display: none!important;
}
.k-grid td {
  padding: 4px 24px;
}
.k-sort-order {
  display: none !important;
}
/*dropdowns contentuo*/
.roles-multiselect {
  /*font-size: 20px !important;*/
}
.name-roles {
  padding-bottom: 10px !important;
  margin-top: -8px !important;
  /*background-color: red !important;*/
}
.nameicon-roles{
  margin-bottom: -10px !important;
  width: 25px !important;
  height: 25px!important;
}
.nameicon-right-roles {
  margin-bottom: -10px !important;
  margin-top: 10px !important;
  /*background-color: red !important;*/
}

/*exp table*/

.equipment-table-exp.k-widget.k-grid {
  /*height: calc( 92vh - 100px) !important;*/
  min-height: calc( 80vh - 90px) !important;
  max-height: calc( 80vh - 90px) !important;
}
.equipment-table-exp th[aria-colindex="3"] {
  text-align: center;
}
.equipment-table-exp td[aria-colindex="3"] {
  text-align: center;
}
.utilizzo-chip {
  margin-left: auto !important;
  margin-right: auto !important;
}
.expiriences-grid th[aria-colindex="2"] {
  text-align: center;
}
.expiriences-grid td[aria-colindex="2"] {
  text-align: center;
}
.expiriences-grid th[aria-colindex="3"] {
  text-align: center;
}
.expiriences-grid td[aria-colindex="3"] {
  text-align: center;
}
.expiriences-grid th[aria-colindex="5"] {
  text-align: center;
}
.expiriences-grid td[aria-colindex="5"] {
  text-align: center;
}

.guest-details-grid th[aria-colindex="6"] {
  text-align: center;
}
.guest-details-grid th[aria-colindex="6"] .k-cell-inner {
  justify-content: center;
}
.guest-details-grid td[aria-colindex="6"] {
  text-align: center;
}
/*equipments-table*/
/*.equipments-grid th[aria-colindex="3"]{*/
/*  text-align: center;*/
/*}*/
/*.equipments-grid td[aria-colindex="3"]{*/
/*  text-align: center;*/
/*}*/
/*.equipments-grid th[aria-colindex="4"]{*/
/*  text-align: center;*/
/*}*/
/*.equipments-grid td[aria-colindex="4"]{*/
/*  text-align: center;*/
/*}*/
/*.equipments-grid th[aria-colindex="5"]{*/
/*  text-align: center;*/
/*}*/
/*.equipments-grid td[aria-colindex="5"]{*/
/*  text-align: center;*/
/*}*/

.partecipants-table th[aria-colindex="5"]{
  text-align: center !important;
  justify-content: center !important;

}
.partecipants-table th[aria-colindex="5"] span{
  text-align: center !important;
  justify-content: center !important;
}
.partecipants-table td[aria-colindex="5"]{
  text-align: center;
  justify-content: center !important;
}
.partecipants-table th[aria-colindex="6"]{
  justify-content: center !important;
  text-align: center;
}
.partecipants-table td[aria-colindex="6"]{
  justify-content: center !important;
  text-align: center;
}
.partecipants-table th[aria-colindex="7"]{
  justify-content: center !important;
  text-align: center;
}
.partecipants-table td[aria-colindex="7"]{
  justify-content: center !important;
  text-align: center;
}
.partecipants-table.in-detail .filter-grid-container {
  max-width: 3% !important;
  flex-basis: 3% !important;
}
.partecipants-table.in-detail .title-bar-grid-container {
  flex-grow: 0;
  max-width: 96% !important;
  flex-basis: 96% !important;
}
.card-404 {
  width: auto !important;
}
.card-login-forpsw .MuiTypography-h3 {
  font-size: 2rem;
}
/*staff table*/
.boolean {
  text-align: center !important;
}



/* FIX FOGLI STILE NUOVO KENDO */
/*.k-window-actions.k-dialog-actions {*/
/*  z-index: 999!important;*/
/*}*/
.k-floating-label-container {
  padding-top: 10px !important;
}
.dropdownModifica .k-floating-label-container {
  padding-top: 0px !important;
}
.k-input-solid::after, .k-input-flat::after, .k-input-outline::after, .k-picker-solid::after, .k-picker-flat::after, .k-picker-outline::after {
  border: none !important;
}
/*DA QUI IN POI MEDIA RULES*/
@media only screen and (max-height: 750px) {
  .item-media-rule {
    /*margin:10px!important;*/
  }
}

@media only screen and (max-width: 750px) {
  .tipo-prezzo-price-form {
    max-width:  100% !important;
    flex-basis: 100% !important;
  }
}
@media (min-width: 650px) {
  .appuntamento-dialog .MuiTypography-h6{
    font-size: 1.0rem !important;
  }
  .MuiGrid-grid-sm-8 {
    flex-grow: 0;
    max-width:  60%;
    flex-basis: 60%;
  }
}
@media only screen and (max-width: 850px) {
  .timetable-planner .MuiGrid-grid-lg-5 {
    flex-grow: 0;
    max-width: 40%;
    flex-basis: 40%;
  }
}
@media only screen and (max-width: 960px) {
  .fasce-gruppi-prezzi-2 {
    margin-left: 0vw !important;
  }

  .fasce-anni-prezzi {
    margin-left: -8vw !important;
  }
  .fasce-anni-prezzi-2 {
    margin-left: -4vw !important;
  }
}
@media only screen and (max-width: 1170px) {
  .uniform-timetable {
    width: 100vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1280px) {
  .tipo-prezzo-price-form {
    margin-left: 0vw !important;
  }
}

@media only screen and (max-width: 1100px) {
  /*.timetable-planner .MuiGrid-grid-lg-5 {*/
  /*  flex-grow: 0;*/
  /*  max-width: 28.666667%;*/
  /*  flex-basis: 28.666667%;*/
  /*}*/
}
@media (max-width: 1760px) {
  .staff-change-single-day-time
  .timetable-row-container{
    margin-left: 0vw !important;
  }
}
@media (min-width: 1280px){

  .delete-fascia-prezzo-eta.MuiGrid-grid-xs-1.MuiGrid-grid-sm-1.MuiGrid-grid-md-1.MuiGrid-grid-lg-1 {
    max-width:  1% !important;
    flex-basis: 1% !important;
  }
  .timetable-planner .MuiGrid-grid-lg-5.day-name-timetable {
    flex-grow: 0;
    max-width:  38% !important;
    flex-basis: 38% !important;
  }
  .timetable-planner-custom .MuiGrid-grid-lg-4.day-name-timetable {
    flex-grow: 0;
    max-width: 31.666667% !important;
    flex-basis: 31.666667% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .delete-fascia-prezzo-eta.MuiGrid-grid-xs-1.MuiGrid-grid-sm-1.MuiGrid-grid-md-1.MuiGrid-grid-lg-1 {
    max-width:  1% !important;
    flex-basis: 1% !important;
  }
  .spacer-left-select {
    max-width: 9% !important;
    flex-basis: 9% !important;
  }
  .timetable-planner .MuiGrid-grid-lg-5.day-name-timetable {
    flex-basis: 38.666667%!important;
    flex-grow: 0;
    max-width: 38.666667%!important;

  }
  .timetable-planner-custom .MuiGrid-grid-lg-4.day-name-timetable {
    flex-grow: 0;
    max-width: 31.666667% !important;
    flex-basis: 31.666667% !important;
  }
  .tipo-prezzo-price-form {
    max-width: 39.5% !important;
    flex-basis: 39.5% !important;
  }
  .prezzinetti-section-title {
    max-width: 24% !important;
    flex-basis: 24% !important;
  }
  .fasce-anni-prezzi {
    margin-left: -2vw !important;
  }
  .fasce-anni-prezzi-2 {
    margin-left: 11vw !important;
  }
  .delete-fascia-prezzo-eta {
    max-width: 0 !important;
    flex-basis: 0 !important;
  }

}
@media only screen and (max-width: 1100px) {
  .timetable-planner .MuiGrid-grid-lg-5.day-name-timetable {
    flex-grow: 0;
    flex-basis: 15% !important;
    max-width:  15% !important;

  }
  .timetable-planner .MuiGrid-grid-sm-8{
    flex-grow: 0;
    max-width:  80% !important;
    flex-basis: 80% !important;
  }
}
@media only screen and (max-width: 1000px) {
  .custom-left-spacing-prices {
    max-width: 0% !important;
    flex-basis: 0% !important;
  }
}
@media only screen and (max-width: 1400px) {
  .dialog-sub .k-widget.k-window.k-dialog{
    width: 100vw!important;
    height: 100vh!important;
  }
}
@media only screen and (max-width: 1500px) {
  .timetable-row-container{
    margin-left: 0vw !important;
  }
}
@media only screen and (max-width: 1700px) {
  .dialog-staff-timetable-form-media-rule
  .timetable-row-container {
    margin-left: -3vw !important;
  }
}

@media only screen and (max-width: 1820px) {
  .MuiGrid-root.chart-container.MuiGrid-item.MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .MuiGrid-root.chart-container.MuiGrid-item.MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .MuiGrid-root.chart-container.MuiGrid-item.MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
@media only screen and (max-width: 1500px) {
  /*.dialog-staff-timetable-form-media-rule {*/
  /*  margin-left: 3vw !important;*/
  /*}*/
  .timetable-row-container .day-name-timetable.MuiGrid-grid-lg-4 {
    flex-grow: 0;
    max-width:  20% !important;
    flex-basis: 20% !important;
  }
  .timetable-row-container .MuiGrid-grid-lg-8 {
    flex-grow: 0;
    max-width:  80% !important;
    flex-basis: 80% !important;
  }
}
@media only screen and (max-width: 1330px) {
  .dialog-staff-timetable-form-media-rule
  .timetable-row-container {
    /*margin-left: 3vw !important;*/
  }
  .timetable-row-container .day-name-timetable.MuiGrid-grid-lg-4 {
    flex-grow: 0;
    max-width:  20% !important;
    flex-basis: 20% !important;
  }
  .timetable-row-container .MuiGrid-grid-lg-8 {
    flex-grow: 0;
    max-width:  80% !important;
    flex-basis: 80% !important;
  }
  .da-a-orari {
    max-width:  130px !important;
    flex-basis: 130px !important;
  }
  .allinea-orari-chiusi {
    max-width:  275px !important;
    flex-basis: 275px !important;
  }
}
@media only screen and (max-width: 1250px) {
  .dialog-staff-timetable-form-media-rule .k-widget.k-window.k-dialog{
    width: 100vw !important;
  }
  .dialog-staff-timetable-form-media-rule .select-week-title {
    width: 98vw !important;
  }
  .timetable-row-container {
    margin-left: 4vw !important;
  }
  .timetable-row-container .day-name-timetable.MuiGrid-grid-lg-4 {
    flex-grow: 0;
    max-width:  30% !important;
    flex-basis: 30% !important;
  }
  .timetable-row-container .MuiGrid-grid-lg-8 {
    flex-grow: 0;
    max-width:  70% !important;
    flex-basis: 70% !important;
  }
  /*.timetable-row-container .day-name-timetable.MuiGrid-grid-md-2 {*/
  /*  flex-grow: 0;*/
  /*  max-width:  20% !important;*/
  /*  flex-basis: 20% !important;*/
  /*}*/
  /*.timetable-row-container .MuiGrid-grid-md-10 {*/
  /*  flex-grow: 0;*/
  /*  max-width:  100% !important;*/
  /*  flex-basis: 100% !important;*/
  /*}*/
}


@media only screen and (max-width: 1445px) {
  .dialog-staff-timetable-form-media-rule .k-chat{
    height: 99% !important;
  }
}

@media only screen and (max-width: 750px) {
  .select-week-title {
    text-align: left !important;
  }
  .timetable-row-container {
    margin-top: -1vh !important;
  }

  .timetable-row-container .day-name-timetable.MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width:  14% !important;
    flex-basis: 14% !important;
  }
  .day-name-timetable {

    text-align: left !important;
  }
  .timetable-row-container .MuiGrid-grid-md-10 {
    flex-grow: 0;
    padding-left: 10vw !important;
    max-width:  100% !important;
    flex-basis: 100% !important;
  }
  .timetable-row-container .MuiGrid-grid-sm-8 {
    flex-grow: 0;
    max-width:  100% !important;
    flex-basis: 100% !important;
  }
  .timetable-row-container .bottoni-timetable.MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width:  40% !important;
    flex-basis: 40% !important;
  }
  .select-week-title .MuiTypography-h6 {

    font-size: 1rem !important;
  }
}
/* cellulare */

@media only screen and (min-width: 700px) and (max-width: 1370px) {
  .company-form .MuiGrid-grid-lg-1 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .company-form .MuiGrid-grid-lg-2 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .company-form .MuiGrid-grid-lg-3 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .company-form .MuiGrid-grid-lg-4 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .company-form .MuiGrid-grid-lg-5 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}

@media only screen and (max-width: 600px) {
  .k-scheduler-cell {
    min-width: calc(100vw - 3vh - 50px);
  }
  .frecce-settimana {
    text-align: center !important;
  }
  .button-media-rule {
    margin: 10px !important;
    width: 98% !important;
  }
  .logout-media-rule {
    padding-top:    5px !important;
    padding-bottom: 5px !important;
  }
}

@media only screen and (max-width: 560px) {
  .dialog-staff-timetable-form-media-rule .timetable-row-container {
    margin-left: -1vw !important;
    padding-left: 0vw!important;
  }
  .timetable-row-container .MuiGrid-grid-md-10 {
    padding-left: 0vw!important;
  }
  .select-week-title {

    top: 8vh !important;
    background-color: white !important;
  }
  .timetable-container {
    margin-top: 8vh !important;

  }
}


@media only screen and (max-width: 768px) and (max-height: 1024px) {

}
@media only screen and (max-width: 1024px) and (max-height: 768px) {


}
@media only screen and (max-width: 1200px) {
  .display-media-rule-1200 {
    display: inline !important;
  }
}
@media only screen and (max-width: 400px) {
  .k-toolbar.k-grid-toolbar {

    height: 8vh !important;
  }


}
@media only screen and (max-width: 570px){
  .a-bit-of-margin-top{
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 1600px){
  .labels-planners .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 1.1rem!important;
  }
  .labelina-fasce-prezzo {
    padding-top: 25px ;
    padding-right: 5px ;
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 1500px){
  .labels-planners .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 1rem!important;
  }
}
@media only screen and (max-width: 1375px){
  .labels-planners .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 0.95rem!important;
  }
}
@media only screen and (max-width: 950px){
  .labels-planners .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 1.1rem!important;
  }
}
@media only screen and (max-width: 900px){
  .labels-planners {
    padding-left: 20px !important;
  }
  .labels-planners .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 0.9rem!important;
  }
}
@media only screen and (max-width: 880px){
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 1rem!important;
  }
}
@media only screen and (max-width: 450px){
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    font-size: 0.8rem!important;
  }
}

/*////DIMENSIONI PIXEL 2XL*/
/*@media only screen and (max-width: 411px) {*/
/*  .item-media-rule {*/
/*    !*width: 100vw !important;*!*/
/*  }*/
/*  .row-media-rule {*/
/*    display: table !important;*/
/*  }*/

/*  .k-toolbar.k-grid-toolbar  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8{*/
/*    display: none;*/
/*  }*/
/*  .k-toolbar.k-grid-toolbar  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3{*/
/*    max-width: 50% !important;*/
/*    flex-basis: 50% !important;*/
/*  }*/
/*  .k-toolbar.k-grid-toolbar  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1{*/
/*    max-width: 50% !important;*/
/*    flex-basis: 50% !important;*/
/*  }*/
/*  .k-toolbar.k-grid-toolbar  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12{*/
/*    max-width: 100% !important;*/
/*    flex-basis: 100% !important;*/
/*  }*/

/*  .change-profile-media-rule  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12{*/
/*    max-width: 100% !important;*/
/*    flex-basis: 100% !important;*/
/*  }*/
/*  .change-profile-media-rule  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6{*/
/*    max-width: 200% !important;*/
/*    flex-basis: 200% !important;*/
/*  }*/
/*  .change-password-media-rule .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12{*/
/*    max-width: 200% !important;*/
/*    flex-basis: 200% !important;*/
/*  }*/

/*  .change-password-media-rule .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6{*/
/*    max-width: 200% !important;*/
/*    flex-basis: 200% !important;*/
/*  }*/

/*  !*.companies_grid td col:nth-of-type(1){*!*/
/*  !*  width: 80%*!*/
/*  !*}*!*/
/*  !*.companies_grid th col:nth-of-type(1){*!*/
/*  !*  width: 80%*!*/
/*  !*}*!*/

/*  !*.companies_grid td td[aria-colindex="1"]{*!*/
/*  !*  width: 80%*!*/
/*  !*}*!*/
/*  !*.companies_grid th td[aria-colindex="1"]{*!*/
/*  !*  width: 80%*!*/
/*  !*}*!*/

/*  !Tabella aziende*/
/*  !*.companies_grid td[aria-colindex="2"]{*!*/
/*  !*  display: none !important;*!*/
/*  !*}*!*/
/*  !*.companies_grid th[aria-colindex="2"]{*!*/
/*  !*  display: none !important;*!*/
/*  !*}*!*/

/*  !*.companies_grid td[aria-colindex="3"]{*!*/
/*  !*  display: none !important;*!*/
/*  !*}*!*/
/*  !*.companies_grid th[aria-colindex="3"]{*!*/
/*  !*  display: none !important;*!*/
/*  !*}*!*/

/*  !*.companies_grid td[aria-colindex="4"]{*!*/
/*  !*  display: none !important;*!*/
/*  !*}*!*/
/*  !*.companies_grid th[aria-colindex="4"]{*!*/
/*  !*  display: none !important;*!*/
/*  !*}*!*/

/*  !*.companies_grid td[aria-colindex="5"]{*!*/
/*  !* !* display: none !important; *!*!*/
/*  !*}*!*/
/*  !*.companies_grid th[aria-colindex="5"]{*!*/
/*  !* !* display: none !important; *!*!*/
/*  !*}*!*/

/*  !*!//Tabella utenti!*!*/
/*  !*.users_grid td[aria-colindex="1"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/
/*  !*.users_grid th[aria-colindex="1"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/

/*  !*.users_grid td[aria-colindex="2"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/
/*  !*.users_grid th[aria-colindex="2"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/

/*  !*.users_grid td[aria-colindex="4"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/
/*  !*.users_grid th[aria-colindex="4"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/
/*  !*.users_grid td[aria-colindex="5"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/
/*  !*.users_grid th[aria-colindex="5"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/

/*  !*.users_grid td[aria-colindex="6"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/
/*  !*.users_grid th[aria-colindex="6"]{*!*/
/*  !*  display: none;*!*/
/*  !*}*!*/

/*  !*.users_grid_attivo {*!*/
/*  !*  padding-left: 60px!important;*!*/
/*  !*}*!*/
/*  !*.users_grid table colgroup col:nth-child(3) {*!*/
/*  !*  width: 90px !important;*!*/
/*  !*}*!*/


/*  .k-grid-table{*/
/*    width: 100% !important;*/
/*    max-width: 100% !important;*/
/*    overflow-x: hidden !important;*/
/*  }*/

/*  .k-toolbar.k-grid-toolbar {*/

/*    height: 8vh !important;*/
/*  }*/


/*  .k-file {*/
/*    height: 10vh !important;*/
/*  }*/
/*  .inline-contents{*/
/*    display: inline !important;*/
/*  }*/

/*  .file-name-upload {*/
/*    width: 55vw !important;*/
/*    float: left !important;*/
/*    padding: 1vh 0vw;*/
/*    padding-left: 2.5vw !important;*/
/*    font-size: 1.0em !important;*/
/*  }*/
/*  .MuiTab-textColorPrimary.Mui-selected{*/
/*    !*color: #3f51b5;*!*/
/*    !*background-color: #d1d1d1 !important;*!*/
/*    !*font-weight: bolder !important;*!*/
/*  }*/

/*  .MuiTypography-h6 {*/
/*    font-size: 1.13rem;*/
/*  }*/
/*  .dropdownCitta {*/
/*    height: 7vh !important;*/
/*  }*/
/*  .button-media-rule {*/
/*    font-size: 0.600rem !important;*/
/*  }*/
/*  .avatar-media-rule .MuiCardHeader-content  span{*/
/*    font-size: 1.2rem !important;*/
/*  }*/
/*  .k-pager-sizes.k-label{*/
/*    font-size: 0.8rem !important;*/
/*  }*/
/*  .k-pager-info.k-label {*/
/*    display:none !important;*/
/*  }*/

/*  .equipment-dialog-form  .k-widget.k-window.k-dialog{*/
/*    height: 100vh!important;*/
/*  }*/
/*  .location-form-media-rule  .k-widget.k-window.k-dialog{*/
/*    height: 100vh!important;*/
/*  }*/
/*  .users-form-media-rule .k-widget.k-window.k-dialog{*/
/*    width: 100vw!important;*/
/*    height: 100vh!important;*/
/*  }*/
/*  .change-profile-media-rule .k-widget.k-window.k-dialog{*/
/*    width: 100vw!important;*/
/*    height: 100vh!important;*/
/*  }*/
/*  .change-password-media-rule .k-widget.k-window.k-dialog{*/
/*    width: 100vw!important;*/
/*    height: 100vh!important;*/
/*  }*/

/*}*/


/*---------------- I PAD -------------*/
/*.locations-page-grid-container .generic-grid-metis*/
/*--------------FINE I PAD -----------*/



/*----------------- responsive cell ----------------- */
@media only screen and (max-width: 1000px) {
  .guest-form
  .k-widget.k-window.k-dialog
  {
    height: 100vh !important;
    max-height: 100vh !important;

  }
  .dropdown-multi-container {
    height: 140px!important;
  }
  .multiselect-icon {
    display: none !important;
  }
  .multiselect-dropdown {
    height: 100px !important;
  }
  /*.role-icon-drowpdown {*/

  /*}*/
  /*.role-label-drowpdown {*/
  /*  !*font-size: 5px!important;*!*/
  /*}*/
  /*.role-description-drowpdown {*/
  /*  font-size: 9px!important;*/
  /*}*/
}
.MuiSelect-root {
  font-family: Gotham !important;
}
.tipo-prezzo-price-form , .prezzo-price-form ,.prezzinetti-section-title {
  font-family: Gotham !important;
}
@media only screen and (max-width: 550px) {

  .k-widget.k-upload.fileupload .file-upload-icon{
    font-size: 1rem !important;
  }
  .k-widget.k-upload.fileupload .file-name-upload{
    width: 60vw !important;
    float: left !important;
    padding-left: 1vw !important;
    font-size: 1rem !important;
    overflow-x: clip !important;
    word-wrap: break-word !important;
  }
  .k-widget.k-upload.fileupload .delete-button-upload{
    margin-top: -1vh !important;
  }

  .fasce-anni-prezzi {
    margin-left: 0vw !important;
  }
  .notifiche-switch {
    margin-left: 2px !important;
  }
  .k-grid td {
    padding: 4px 18px !important;
  }

  .attiva-switch {
    padding-top: 10px !important;
    text-align: left !important;
    padding-left: 10px !important;
  }
  .grid-orari {
    font-size: 10px !important;
  }
  .rows-grandi {
    height: 80px !important;
  }
  .dropdown-multi-container {
    height: 120px!important;
  }
  .k-window-content {
    padding: 8px 12px !important;
    overflow-x: hidden !important;
  }
  .staff-change-single-day-time .k-window-content {
    padding: 8px 10px !important;
    overflow-x: hidden !important;
  }
  .equipments-grid, .expiriences-grid , .staff-table,  .locations-page-grid-container{
    padding: 0 !important;
  }
  .equipments-grid .k-widget.k-grid, .expiriences-grid .k-widget.k-grid, .staff-table .k-widget.k-grid, .locations-page-grid-container .k-widget.k-grid{
    min-height: 100vh !important;
    max-height: 100vh !important;
  }
  .expiriences-grid  .equipment-table-exp.k-widget.k-grid {
    min-height: calc(80vh - 90px)!important;
    max-height: calc(80vh - 90px)!important;
  }
  .equipments-grid .k-grid-header, .partecipants-table .k-grid-header,  .container-messages-section .k-grid-header , .staff-table .k-grid-header, .expiriences-grid  .k-grid-header ,  .locations-page-grid-container .k-grid-header, .equipment-table-exp .k-grid-header{
    display: none !important;
  }
  .partecipants-table .k-icon.k-i-filter , .locations-page-grid-container .k-icon.k-i-filter,
  .equipments-grid .k-icon.k-i-filter,  .partecipants-table .k-icon.k-i-filter,
  .staff-table .k-icon.k-i-filter, .expiriences-grid .k-icon.k-i-filter
  {
    display: none !important;
  }
  .equipments-grid .title-bar-grid , .staff-table .title-bar-grid , .expiriences-grid .title-bar-grid, .locations-page-grid-container .title-bar-grid {
    padding-left: 10px !important;
    padding-top: 2px !important;
  }
  .locations-page-grid-container .k-grid-content  , .staff-table .k-grid-content , .expiriences-grid .k-grid-content {
    overflow-x: hidden !important;
  }
  .expiriences-grid .equipment-table-exp .k-grid-content {
    overflow-x: scroll !important;
  }

  .equipment-dialog-form
  .k-widget.k-window.k-dialog
  {
    height: 100vh !important;
    max-height: 100vh !important;
    overflow-y: scroll !important;
  }
  .location-form-media-rule
  .k-widget.k-window.k-dialog
  {
    height: 100vh !important;
    max-height: 100vh !important;
    overflow-y: scroll !important;
  }


  .dialog-expirience-form .k-grid table {
    width: unset !important;
    font-size: 12px !important;
  }
  .fascia-prezzo-rule {
    margin-bottom:3vh !important;
  }
  .add-equip-dialog {
    margin-right: 0vw !important;
    margin-left: 12vw !important;
  }
  .add-fascia-btn-container {
    padding-left: 0px !important;
  }
  .add-period-season {
    font-size: 10px !important;
  }
  .fasce-anni-prezzi-2 {
    margin-left: 0px !important;
  }
  .fasce-anni-eta {
    max-width: initial !important;
    flex-basis: initial !important;
  }
  .rows-grandi-3 {
    height: 120px !important;
  }
  .bottoni-calcola-prezzi {
    font-size:10px !important;
  }
  .delete-fascia-bottone span {
    font-size: 8px !important;
    height: 5px !IMPORTANT;
    width: 3px !important;
  }
  .price-to-discount{
    margin-left: 0px !important;
  }

  /*price form beta*/
  .discount-title-pfb {
    padding-left: 7vw !important;
  }
  .titles-priceformbeta {
    font-size: 15px !important;
  }
  .sconto-prezzinetti {
    position: relative;
    left: 20px !important;
  }
  .group-discount-label {
    padding-top: 0px !important;
  }
  .title-giorno-timetable {
    font-size: 13px !important;
  }
  .spacer-left-select , .custom-left-spacing-prices{
    display: none !important;
  }
  .tipo-prezzo-price-form , .prezzo-price-form {
    margin: 0 !important;
    margin-top: 2vh !important;
    max-width: initial !important;
    flex-basis: initial !important;
    width: 100% !important;
  }
  /*tabs*/
  .MuiTab-root {
    font-size: 0.5rem !important;
  }

  /*grafici*/
  .chart-container {
    margin: 5px !important;
    margin-left: 0px !important;
  }
  .chart-container.graph .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 600px !important;
    flex-basis: 600px !important;
    width: 600px !IMPORTANT;
    overflow-x: scroll !important;
  }
  .chart-container.graph.graph-histogram .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 600px !important;
    flex-basis: 600px !important;
    width: 800px !IMPORTANT;
    overflow-x: scroll !important;
  }
  .chart-container.graph.graph-histogram .k-chart-surface {
    height: 95%;
    width: 900px !important;
    touch-action: initial !important;
  }
  .chart-container.graph .k-chart-surface {
    height: 95%;
    width: 900px !important;
    touch-action: initial !important;
  }
  /*price form*/
  .labelina-fasce-prezzo {
    padding-top: 10px !important;
    padding-right: 5px;
    font-size: 0.8rem !important
  }
  .container-input-fasce-prezzo {
    max-width: inherit !important;
    flex-basis: inherit !important;
  }

  /*palnner popover*/
  .k-widget.k-grid.agende-table {
    max-height: 80vh !important;
    min-height: 20vh !important;
    height: auto !important;
    width: 95vw !important;
    font-size: 12px;
    font-weight: normal !important;
    overflow: auto !important;
    overflow-y: scroll !important;
  }

  .card-dialog-from-drawer .MuiCardContent-root{
    padding: 0px !important;
  }

  .actions-popover-planner .MuiButton-root {
    font-size: 12px !important;
  }
  .actions-popover-planner {
    padding-left: 0px !important;
  }

  .card-dialog-from-drawer {
    width: 100vw !important;
    height: 80vh !important;
  }
  .card-dialog-from-drawer .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    opacity: 1;
    transform: none;
    transition: opacity 339ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 226ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 0px !important;
    left: 0px !important;
    transform-origin: 196.109px 0px;
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0px !important;
  }
  .card-dialog-from-drawer .MuiPopover-paper {
    max-height: 100vh !important;
    max-width: 100vw !important;
  }
  .actions-popover-planner {
    padding-left: 20px !important;
  }
  /*drawer*/
  .button-planner-toolbar .MuiButton-label{
    font-size: 10px !important;
  }
  /*testo bottoni drawer menu*/
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock{
    font-size: 14px !important;
  }
  /*icona bottoni drawer menu*/
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button .MuiListItemIcon-root {
    min-width: 35px !important;
  }
  /*card dialog in generale*/
  .card-dialog  .k-widget.k-window.k-dialog{
    width: 100vw !important;
    height: 100vh !important;
  }
  .user-control-container {
    text-align: left!important;
  }
  .user-control-container .button-media-rule {
    width: inherit !important;
    margin: 0px !important;
  }
  #now-my-place-icon {
    height: 30px !important;
    width: 16px !important;
    margin-left: 10px !important;
  }
  #now-my-place-icon-text {
    height: 15px !important;
  }
  .grid-button {
    padding: 6px !important;
    padding-left: 15px !important;
  }
  .custom-filter-button {
    height: 30px !important;
  }
  .grid-button .MuiSvgIcon-root {
    font-size: 1.2rem !important;
  }
  .title-right-icon-container {
    font-size: 20px !important;
  }
  .custom-unif-icon-title {
    font-size: 25px !important;
  }
  .title-icon-grid {
    display: none !important;
  }


  .grid-orari {
    font-size: 12px !important;
    overflow-x: hidden !important;
  }
  /*settimana*/
  .grid-orari  table colgroup col:nth-child(1){
    width: 130px !important;
  }
  /*pianificazione*/
  .grid-orari  table colgroup col:nth-child(2){
    width: 140px !important;
  }
  /*tipo orario*/
  .grid-orari  table colgroup col:nth-child(3){
    width: 130px !important;
  }

  .k-multiselect.k-floatwrap {
    overflow-y: scroll !important;
    height: 75px !important;
  }

  .role-description-drowpdown {
    display: none !important;
  }
  .k-dropzone-hint {
    display: none !important;
  }
  .dashboard-page {

  }
  /* APPUNTAMENTO */


  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled
  {
    font-size: 16px !important;
    padding-right: 2px!important;
    padding-left: 2px!important;
    background-color: white !important;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined
  {
    font-size: 16px !important;
    padding-right: 2px!important;
    padding-left: 2px!important;
    background-color: white !important;
  }
  .MuiInputLabel-outlined {
    font-size: 16px !important;
    padding-right: 0px!important;
    padding-left: 2px!important;
    background-color: white !important;
  }

  /*x dialog*/
  .dialog-expirience-form  .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .equipment-dialog-form .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .location-form-media-rule .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .chat-dialog .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .dialog-sub .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .add-planner-dialog .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .appuntamento-dialog .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close,
  .dialog-staff-form-media-rule .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close
  {
    margin-right: 0px !important;
  }
  /*titoli delle dialogs*/
  .equipment-dialog-form .effective-title-icon , .guest-form .effective-title-icon ,  .dialog-expirience-form .effective-title-icon,  .location-form-media-rule .effective-title-icon, .dialog-sub .effective-title-icon,
  .add-planner-dialog .effective-title-icon,  .appuntamento-dialog .effective-title-icon, .dialog-staff-form-media-rule  .effective-title-icon
  {
    font-size: 20px !important;
    /*display: none !important;*/
    /*display:  !important;*/
  }

  .dialog-staff-form-media-rule .k-window-title.k-dialog-title .MuiTypography-root.MuiTypography-h6{
    font-size: 14px !important;
  }
  .dialog-staff-form-media-rule .start-message-icon-title {
    /*display: none !important;*/
  }

  .equipment-dialog-form .k-window-title.k-dialog-title .MuiTypography-root.MuiTypography-h6{
    font-size: 14px !important;
  }
  .equipment-dialog-form .start-message-icon-title {
    /*display: none !important;*/
  }

  .equipment-dialog-form .effective-title {
    margin-left: -5px !important;
    font-size: 16px !important;
    z-index: 999 !important;
  }
  .location-form-media-rule .k-window-title.k-dialog-title .MuiTypography-root.MuiTypography-h6{
    font-size: 18px !important;
  }
  .location-form-media-rule .start-message-icon-title {
    /*display: none !important;*/
  }
  .location-form-media-rule .effective-title {
    font-size: 22px !important;
    z-index: 999 !important;
    margin-top: -5px !important;
  }
  .dialog-expirience-form .k-window-title.k-dialog-title .MuiTypography-root.MuiTypography-h6{
    font-size: 14px !important;
  }
  .dialog-expirience-form .start-message-icon-title {
    /*display: none !important;*/
  }
  .dialog-expirience-form .effective-title {
    margin-left: 5px !important;
    font-size: 14px !important;
    z-index: 999 !important;
  }

  .chat-dialog .k-window-title.k-dialog-title .MuiTypography-root.MuiTypography-h6{
    font-size: 16px !important;
  }
  .dialog-sub .start-message-icon-title {
    display: none !important;
  }
  .staff-change-single-day-time .effective-title {
    margin-left: 15px !important;
    font-size: 14px !important;
    z-index: 999 !important;
    float: left !important;
    position: absolute;
    margin-top: 0px !important;
    /*margin-top: 20px !important;*/
  }
  .dialog-sub .effective-title {
    margin-left: 15px !important;
    font-size: 18px !important;
    z-index: 999 !important;
    float: left !important;
    position: absolute;
    margin-top: -5px !important;
    /*margin-top: 20px !important;*/
  }
  .add-planner-dialog .effective-title {
    margin-left: 15px !important;
    font-size: 18px !important;
    z-index: 999 !important;
    float: left !important;
    position: absolute;
    /*margin-top: 20px !important;*/
  }
  .appuntamento-dialog .effective-title {
    margin-left: 20px !important;
    font-size: 15px !important;
    z-index: 999 !important;
    float: left !important;
    position: absolute;
    margin-top: 0px !important;
  }
  .guest-form .effective-title {
    margin-left: 20px !important;
    font-size: 15px !important;
    z-index: 999 !important;
    float: left !important;
    position: absolute;
    margin-top: 0px !important;
  }
  .appuntamento-dialog .k-button.k-flat.k-button-icon.k-window-action.k-dialog-action.k-dialog-close {
    margin-right: 0px!important;
    margin-top: -10px !important;
  }


  /*  planner-form-page*/
  .planner-form-page {
    padding: 0 !important;
  }
  .general-info , .open-periods{
    padding: 10px !important;
  }
  .bottoni-tondi-responsive {
    margin-top: 5px !important;
  }
  .bottoni-tondi-responsive span{
    font-size: 20px!important;
    height: 4px !IMPORTANT;
    width: 4px !important;
    font-weight: lighter !important

  }

  /*  agende e periodi apertura*/
  .add-edit-season-dialog .k-datepicker {
    width: 100% !important;
  }
  .add-delete-time {
    max-width:  31% !important;
    flex-basis: 31% !important;
  }
  .dialog-staff-timetable-form-media-rule .timetable-row-container {
    margin-left: -3vw !important;
    padding-left: 0vw!important;
  }

  .custom-section-forms-timetable {
    margin-top: -10px !important;
    /* margin-left: 4vw !important; */
    padding-left: 0px !important;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .da-a-orari {
    max-width: 95px !important;
    flex-basis: 95px !important;
  }
  .allinea-orari-chiusi {
    max-width: 210px !important;
    flex-basis: 210px !important;
  }
  .add-time {
    margin-left: 20px !important;
    margin-right: 0px !important;
  }
  .select-week-title {
    top: 2vh !important;
    background-color: white !important;
    left: 8vw;
  }

  .select-week-title .MuiTypography-h6 {
    font-size: 1rem !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .bottoni-timetable-container button {
    margin-top:     10px !important;
    margin-bottom:    0px !important;
    margin-left: 10px !important;
  }
  .dialog-staff-timetable-form-media-rule .k-window-content {
    padding: 2px !important;
  }
  .bottoni-timetable-container {
    display: grid !important;
  }
  .timetable-row-container .bottoni-timetable.MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width:  32% !important;
    flex-basis: 32% !important;
  }
  .timetable-container {
    margin-top: 1vh !important;
  }
  .left-spacing-40p {
    margin-left: 18px !important;
  }

  .field-attrezzatura-number-equipment-table
  .MuiFormControl-fullWidth {
    width: 130% !important;
  }

  .equipment-table-exp
  .expiriences-grid .k-grid-content, .locations-page-grid-container .k-grid-content, .staff-table .k-grid-content {
    overflow-x: auto !important;
  }

  .staff-change-single-day-time .labels-planners {
    padding-left: 0px !important;
  }
}