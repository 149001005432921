.delete-item-dialog .k-window-content.k-dialog-content,
.add-edit-season-dialog .k-window-content.k-dialog-content {
    flex: unset!important;
}
.delete-item-dialog .k-window.k-dialog {
   height: 250px !important;
}
.delete-item-dialog.form-availability .k-window.k-dialog {
   height: 190px !important;
}
.add-edit-season-dialog  .k-window.k-dialog{
    height: 210px !important;
}

.k-window-content {
    padding: 6px 24px;
}
.k-dialog-wrapper.equipment-dialog-form .k-window-content{
    /*height: 50vh;*/
}
.k-window-actions {
   margin: 0 0 !important;
    }
.k-dialog-wrapper .k-dialog {
    position: relative;
    /*height: 160px;*/
}
.delete-item-dialog div.k-window.k-dialog > div.k-actions.k-actions-horizontal.k-window-actions.k-dialog-actions.k-actions-stretched ,
.add-edit-season-dialog div.k-window.k-dialog > div.k-actions.k-actions-horizontal.k-window-actions.k-dialog-actions.k-actions-stretched {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0,0,0,.87);
    border: 0 solid;
    box-sizing: border-box;
    clear: both;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: inherit;
    border-style: solid;
    border-width: 1px 0 0;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    gap: 8px;
    overflow: hidden;
    padding: 8px;
}

.k-chat .k-chat-bubble {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 14px;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    box-sizing: inherit;
    word-wrap: break-word;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    line-height: 1.25;
    padding: 8px 12px;
    white-space: pre-wrap;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,.08);
    color: rgba(0,0,0,.87);
    order: -1;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    border-bottom-left-radius: 2px;
}

.k-chat .k-alt .k-chat-bubble {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 14px;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 800;
    text-align: right;
    box-sizing: inherit;
    word-wrap: break-word;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    line-height: 1.25;
    padding: 8px 12px;
    white-space: pre-wrap;
    order: -1;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    background-color: #3f51b5;
    border-color: #3f51b5;
    color: #fff;
    box-shadow: 0 1px 2px rgba(63,81,181,.2);
    border-bottom-right-radius: 2px;
}
.k-message-group .k-avatar{
    top: 5px;
}
.k-message-group .k-author{
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 10px;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    box-sizing: inherit;
    word-wrap: break-word;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    line-height: 1.25;
    padding: 2px 8px;
    white-space: pre-wrap;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,.08);
    color: rgba(0,0,0,.87);
    order: -1;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    border-bottom-left-radius: 2px;
}
.k-message-group.k-alt .k-author{
    webkit-font-smoothing: antialiased;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 10px;
    font-family: Gotham !important;
    font-style: normal;
    font-weight: 800;
    text-align: right;
    box-sizing: inherit;
    word-wrap: break-word;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    line-height: 1.25;
    padding: 2px 8px;
    white-space: pre-wrap;
    order: -1;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
    background-color: #3f51b5;
    border-color: #3f51b5;
    color: #fff;
    box-shadow: 0 1px 2px rgba(63,81,181,.2);
    border-bottom-right-radius: 2px;
}
.std-chart-container-area {
    padding: 12px;
}
.std-multiselect-container {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    line-height: 2;
    color: rgba(0,0,0,.87);
    box-sizing: inherit;
    border: 1px solid rgba(0,0,0,.23)!important;
    border-radius: 5px;
    height: 57px!important;
    padding: 10px 0!important;
    margin: 0;
    display: inline-flex;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}
.std-multiselect-container .k-chip-list {
    max-height: 30px !important;
    overflow-y: auto;
}
.std-multiselect-label {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: inherit;
    font-family: GothamBold!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    transform: translate(14px, -6px) scale(0.75);
    background-color: #fff!important;
    font-size: 1.2rem!important;
    padding-right: 20px!important;
}
.std-multiselect-component {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
}
.std-multiselect-component .k-input-inner{
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Gotham!important;
    font-weight: bolder!important;
    box-sizing: border-box;
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: inherit;
    flex: 1 1;
    font: inherit;
    outline: 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    width: 10% !important;
    display: none !important;
    z-index: 1;
    padding: 8px 16px;
    margin: -4px;
}
.std-multiselect-component .k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base{
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    letter-spacing: 0.00938em;
    text-align: start;
    font-weight: 700!important;
    direction: ltr;
    unicode-bidi: isolate;
    box-sizing: border-box;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    flex-flow: row nowrap;
    font-family: Roboto,Helvetica Neue,sans-serif;
    gap: 4px;
    justify-content: center;
    outline: 0;
    overflow: hidden;
    position: relative;
    user-select: none;
    font-size: 14px;
    line-height: 1.2857142857;
    padding: 4px;
    background-color: hsla(0,0%,92%,.987);
    color: rgba(0,0,0,.87);
    border-color: transparent;
    border-radius: 25px;
}
.std-multiselect-component .multiselect-icon{
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 1.05rem!important;
    font-weight: 450!important;
    cursor: text;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    box-sizing: inherit;
    display: inline;
    color: black;
    width: 26px;
    height: 18px;
    text-align: left;
    margin-right: 10px;
    margin-top: -6px;
}
.std-adornment {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    box-sizing: inherit;
    height: 0.01em;
    display: flex;
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
    margin-right: 8px;
}
.std-dropdown-container {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    line-height: 2;
    color: rgba(0,0,0,.87);
    box-sizing: inherit;
    border: 1px solid rgba(0,0,0,.23)!important;
    border-radius: 5px;
    height: 57px!important;
    padding: 10px 0!important;
    margin: 0;
    display: inline-flex;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}
.std-dropdown-colored-container {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    line-height: 2;
    color: rgba(0,0,0,.87);
    box-sizing: inherit;
    border: 1px solid rgba(0,0,0,.23)!important;
    border-radius: 5px;
    padding: 0!important;
    margin: 0;
    display: inline-flex;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}
.std-dropdown-label {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: inherit;
    font-family: GothamBold!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    transform: translate(14px, -6px) scale(0.75);
    background-color: #fff!important;
    font-size: 1.2rem!important;
    padding-right: 20px!important;
}
.std-dropdown-colored-label {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: inherit;
    font-family: GothamBold!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    padding-left: 4px!important;
    transform: translate(14px, -6px) scale(0.75);
    font-size: 1.2rem!important;
    background-color: hsla(0,0%,100%,.6)!important;
    padding-right: 0!important;
}
.std-dropdown-component {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Gotham!important;
    font-style: normal;
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
}
.std-dropdown-colored-component {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
    background-color: rgb(221, 237, 253);
    padding-bottom: 8px;
}
.std-dropdown-component .k-input-inner ,
.std-dropdown-component .k-input-value-text {
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 800;
}
.std-button {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    color: #fff!important;
    font-weight: bolder!important;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    border-radius: 50px;
}

.std-time-picker-component {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-align: center;
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: 100%;
    position: relative;
    border-radius: 4px;

}
.std-time-picker-container {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 14px;
    line-height: 1.5;
    color: inherit;
    text-align: center;
    flex-basis: 170px!important;
    max-width: 170px!important;
    border: 1px solid rgba(0,0,0,.23)!important;
    border-radius: 5px;
    margin: 10px!important;
    padding: .3vh .5vw!important;
    display: inline-flex;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    flex-grow: 0;
}
.std-time-picker-scheduler-container {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    line-height: 2;
    color: rgba(0,0,0,.87);
    box-sizing: inherit;
    border: 1px solid rgba(0,0,0,.23)!important;
    border-radius: 5px;
    height: 57px!important;
    padding: 10px 0!important;
    margin: 0;
    display: inline-flex;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}
.std-time-picker-scheduler-label {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: inherit;
    font-family: GothamBold!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    padding-left: 4px!important;
    transform: translate(14px, -6px) scale(0.75);
    background-color: #fff!important;
    font-size: 1.2rem!important;
    padding-right: 20px!important;
}
.std-time-picker-scheduler-component {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Gotham!important;
    font-style: normal;
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
}
.std-time-picker-label {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-align: center;
    box-sizing: inherit;
    font-family: GothamBold!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    transform: translate(14px, -6px) scale(0.75);
    background-color: #fff!important;
    font-size: 1.2rem!important;
    padding-right: 20px!important;
    padding-left: 10px;
}
.std-date-picker-container {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.01071em;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 2;
    color: rgba(0,0,0,.87);
    box-sizing: inherit;
    border: 1px solid rgba(0,0,0,.23)!important;
    border-radius: 5px;
    height: 57px!important;
    padding: 10px 0!important;
    margin: 0;
    display: inline-flex;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    margin-top: 0;
    width: 100%;
}
.std-date-picker-component {
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-size: 1.05rem!important;
    font-weight: 450!important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.00938em;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
    line-height: 1.2876em;
}
.std-date-picker-label {
    -webkit-font-smoothing: antialiased;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: inherit;
    font-family: GothamBold!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    transform: translate(14px, -6px) scale(0.75);
    background-color: #fff!important;
    font-size: 1.2rem!important;
    padding-right: 20px!important;
}
.std-chart-container-histogram {
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 500;
    border-radius: 25px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,.56);
    margin: 20px!important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 12px;
}
.std-chart-container-pie {
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 500;
    border-radius: 25px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,.56);
    margin: 20px!important;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 12px;
}
.std-chart-container-area {
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 25px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,.56);
    margin: 20px!important;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 12px;
}
.std-chart-container-years {
    -webkit-font-smoothing: antialiased;
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    border-radius: 25px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,.56);
    margin: 20px!important;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 12px;
    flex-basis: 36.5%!important;
    flex-grow: 0;
    max-width: 36.5%!important;
}
.css-1uccc91-singleValue {
    font-family: 'Gotham' !important;
    font-weight: 700;
    font-style: normal;
}
.std-bottoni-tondi {
    font-size: 0.5rem !important;
    padding: 8px !important;
}

.std-dialog-actions-container {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0,0,0,.87);
    border: 0 solid;
    box-sizing: border-box;
    clear: both;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: inherit;
    border-style: solid;
    border-width: 1px 0 0;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    gap: 8px;
    overflow: hidden;
    padding: 8px;
}
.std-dialog-action-button {
    flex: 1 0;
    font-weight: bolder!important;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    padding: 6px 8px;
    border-radius: 50px;
}
.std-singleupload-container {
    -webkit-font-smoothing: antialiased;
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 14px;
    line-height: 2;
    color: rgba(0,0,0,.87);
    box-sizing: inherit;
    font-family: Gotham!important;
    font-style: normal;
    font-weight: 500;
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}
.std-singleupload-label {
    -webkit-font-smoothing: antialiased;
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-style: normal;
    box-sizing: inherit;
    font-family: GothamBold!important;
    transform: translate(14px,-10px) scale(.75)!important;
    font-size: 1.2rem!important;
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.00938em;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    background-color: #fff!important;
    padding-right: 20px!important;
}
.k-pager-sizes {
    /*display: none*/
    -webkit-font-smoothing: antialiased;
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: default;
    font-family: Roboto,Helvetica Neue,sans-serif;
    line-height: 2;
    white-space: nowrap;
    color: rgba(0,0,0,.54);
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 1.2rem!important;
}
.k-pager-info {
    -webkit-font-smoothing: antialiased;
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    font-weight: 400;
    letter-spacing: 0.01071em;
    --kendo-scrollbar-width: 17px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: default;
    font-family: Roboto,Helvetica Neue,sans-serif;
    line-height: 2;
    white-space: nowrap;
    color: rgba(0,0,0,.54);
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    margin-right: 8px;
    flex: 1 1;
    justify-content: flex-end;
    order: 9;
    text-align: right;
    font-size: 1.2rem!important;
}
.std-grid-component {
    max-height: calc(94vh - 64px)!important;
    min-height: calc(94vh - 64px)!important;
}
.std-grid-component-inside-dialog {
    max-height: 80vh!important;
    min-height: 80vh!important;
}
.staff-change-single-day-time .k-window-content.k-dialog-content{
    overflow: hidden !important;
}
.availability-grid-container {
    margin-top: 10px !important;
    padding-top: 15px !important;
}
.availability-switch-container {
    margin-bottom: 10px;
}
.experience-availability-title {
    margin-top: 1vh;
    margin-bottom: 1vh;
}
.experience-availability-label {
    font-weight: normal !important;
}
.availability-range-button {
    margin-top: 8px;
    margin-right: 10px !important;
}
.new-period-btn {
    margin-top: 30px !important;
    margin-right: 0px !important;
    margin-left: 40px !important;
}
.errori-dentro-availability{

    font-size: 0.8rem;
}
.availability-range-radio {
    float: left;
    margin-right: 20px ;
    margin-top: 15px !important;
}
.availability-range-radio.k-radio-lg {
    width: 30px !important;
    height: 30px !important;
}
.availability-range-radio.k-radio:checked,
.availability-range-radio.k-radio.k-checked
{
    border-color: #5c43b5 !important;
    color: #5c43b5 !important;
    background-image: url('./../img/radio.svg');
    /*background-color: #5c43b5 !important;*/
}
.width-30 {
    width: 30% !important;
}
.availability-days-multiselect, .availability-times-multiselect {
    width: 40vw !important;
}
.availability-delete-row-btn {
    margin-top: 8px;
}
.availability-switch {
    padding-top: 14px !important;
    text-align: center;
}
.grid-orari-agenda, .grid-orari {
    max-height: 670px !important;
}
.superadmin-dialog-subtitle {
    padding-top: 0px !important;
}
.dialog-sub {
    z-index: 1205 !important;
}
@media only screen and (max-width: 1250px) {
    .dialog-staff-timetable-form-media-rule  .k-dialog {
        width: 100vw  !important;
    }
    .dialog-staff-timetable-form-media-rule .timetable-row-container .MuiGrid-grid-lg-8 {
        flex-grow: 0;
        max-width: 76% !important;
        flex-basis: 76% !important;
    }
}

@media only screen and (max-width: 1536px) {
    .availability-days-multiselect, .availability-times-multiselect {
        width: 400px !important;
    }
}
